import { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { Col, Row } from "react-bootstrap";
import Icon from "../../../_metronic/assets/Icons/xfinlogo.svg";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { RecordPayModal } from "../Invoice/RecordPay";
import Swal from "sweetalert2";

interface details {
  invoiceGuid: string;
}
interface InvPreviewData {
  tenantId: string;
  referenceId: string;
  invoiceGuid: string;
  invoiceNumber: string;
  businessName: string;
  businessAddress: string;
  customerGuid: string;
  customerName: string;
  invoiceDate: string;
  dueDate: string;
  subTotal: number;
  discount: number;
  tax: number;
  shipping: number;
  total: number;
  termsAndConditions: string;
  notes: string;
  invStatus: number;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  customerEmailId: string;
  items: [
    {
      tenantId: string;
      referenceId: string;
      invoiceGuid: string;
      itemName: string;
      qty: number;
      rate: number;
      total: number;
    }
  ];
}

interface Todetails {
  businessName: string;
  businessAddress: string;
  businessIsdCode: number;
  businessMobileNo: string;
  businessEmailId: string;
}

interface setting {
  invoicePrefix: "";
  invoiceSequencePattern: "";
  termsAndConditions: "";
  dueDatePeriod: "";
  tax: "";

  documents: [
    {
      documentExtention: "";
      documentName: "logo";
      documentTypes: [1];
      documentUploadedByType: 1;
      documentStatus: 4;
      fileUploadType: 1;
      documentPath: "";
    },
    {
      documentExtention: "";
      documentName: "signature";
      documentTypes: [9];
      documentUploadedByType: 1;
      documentStatus: 4;
      fileUploadType: 1;

      documentPath: "";
    }
  ];
}
type TimelineEvent = {
  date: string;
  time: string;
  description: string;
  createdBy: string;
};

export function QuotationDetails(data: { data?: details | null }) {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const detailsModal = useRef<HTMLDivElement | null>(null);
  const baseCurrency = sessionStorage.getItem("currency");

  const [invData, setData] = useState<InvPreviewData | null>(null);
  const [toData, setToData] = useState<Todetails | null>(null);
  const [invSetting, setInvSetting] = useState<setting | null>(null);

  const id = data?.data?.invoiceGuid;

  const sampleData: any[] = [
    { itemName: "Polish", rate: "20", quantity: "10", total: "200" },
    { itemName: "Pencil", rate: "5", quantity: "10", total: "50" },
    { itemName: "Pen", rate: "10", quantity: "100", total: "1000" },
  ];

  useEffect(() => {
    if (detailsModal.current) {
      const modal = new Modal(detailsModal.current);
      detailsModal.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        detailsModal.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleRecordPay = () => {
    const modalElement = document.getElementById("recordpay_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDeleteConfirmation = () => {
    const modalElement = document.getElementById("details_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDeleteCloseModal = () => {
    const modalElement = detailsModal.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
    //handleDeleteConfirmation()
  };
  const LoadInvoice = async () => {
    try {
      const url = `${API_URL}/invoice/load`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        invoiceGuid: id,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setData(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const getInvoiceData = async () => {
    try {
      const url = `${API_URL}/invoice/init`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setToData(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data?.data) {
      LoadInvoice();
      getInvoiceData();
    }
  }, [data]);

  const LoadSettings = async () => {
    try {
      const url = `${API_URL}/invSetting/load`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setInvSetting(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LoadSettings();
  }, []);

  const timelineEvents: TimelineEvent[] = [
    {
      date: "01 Jan 2024",
      time: "06:19 AM",
      description: "Invoice created - IDS/TI/23-24/363",
      createdBy: "Xfin Billing",
    },
    {
      date: "01 Jan 2024",
      time: "06:19 AM",
      description: "Changed quotation was resent to E-mail as per request",
      createdBy: "Xfin Billing",
    },
    {
      date: "23 Dec 2023",
      time: "06:10 AM",
      description: "Quotation change request was created",
      createdBy: "Xfin Billing",
    },
    {
      date: "23 Dec 2023",
      time: "06:10 AM",
      description: "Quotation created successfully",
      createdBy: "Xfin Billing",
    },
  ];

  return (
    <>
      <style>
        {`
        .timeline-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
  position: relative;
}

.timeline-event {
  display: flex;
  margin-bottom: 20px;
}

.timeline-left {
  width: 150px;
  text-align: right;
  padding-right: 20px;
}

.timeline-date {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.timeline-time {
  font-size: 12px;
  color: #888;
}

.timeline-right {
  position: relative;
  flex: 1;
  padding-left: 40px;
}

.timeline-circle {
  width: 10px;
  height: 10px;
  background-color: #007bff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 5px;
  z-index: 1;
}

.timeline-content {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.timeline-description {
  font-size: 14px;
  color: #333;
}

.timeline-createdBy {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
}

.timeline-line {
  position: absolute;
  width: 2px;
  background-color: #007bff;
  top: 0;
  bottom: 0;
  left: 154px;
  z-index: 0;
}

        `}
      </style>
      <div
        className="modal"
        tabIndex={-1}
        id="quo_details_modal"
        ref={detailsModal}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-top rounded"
          style={{ margin: "0 auto", marginTop: "5%", width: "1000px" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-column justify-content-center align-items-center p-5">
                <div className="d-flex flex row w-100 justify-content-between">
                  <img
                    className="image-input-wrapper w-125px h-125px"
                    style={{ objectFit: "contain" }}
                    src={invSetting?.documents[0]?.documentPath}
                    alt=""
                  />
                  <div className="d-flex flex-column gap-10 justify-content-end align-items-center w-250px h-100px mb-5">
                    <div
                      className="btn btn-icon btn-sm ms-2"
                      style={{
                        alignSelf: "end",
                      }}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      X
                    </div>
                    <p
                      className="fs-4 fw-bold text-end"
                      style={{
                        alignSelf: "end",
                      }}
                    >
                      Quotation No: {invData?.invoiceNumber}
                    </p>
                  </div>
                </div>
                <div className="row mb-4 w-100 justify-content-between">
                  <div className="d-flex flex-column w-50 text-start">
                    <label className="text-dark mb-2 fw-bold fs-4">
                      Quotation Date
                    </label>
                    <span className="text-primary bg-secondary p-2 rounded fw-bold">
                      {invData?.invoiceDate
                        ? new Date(invData.invoiceDate).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )
                        : ""}
                    </span>
                  </div>
                  <div className="d-flex flex-column w-50 text-end">
                    <label className="text-dark mb-2 fw-bold fs-4">
                      Due Date
                    </label>
                    <span className="text-primary bg-secondary p-2 rounded fw-bold">
                      {invData?.dueDate
                        ? new Date(invData.dueDate).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="mb-2 w-100 ">
                  <form>
                    <div className="row">
                      <div className="col">
                        <label htmlFor="" className="fw-bold fs-4">
                          To
                        </label>
                        <textarea
                          className="form-control resize-none"
                          value={
                            invData?.businessName +
                            "\n" +
                            invData?.businessAddress +
                            "\n" +
                            invData?.customerEmailId
                          }
                          cols={15}
                          rows={4}
                          maxLength={150}
                        ></textarea>
                      </div>
                      <div className="col">
                        <label htmlFor="" className="fw-bold fs-4">
                          From
                        </label>
                        <textarea
                          className="form-control resize-none"
                          value={
                            toData?.businessName +
                            "\n" +
                            toData?.businessAddress +
                            "\n" +
                            "+" +
                            toData?.businessIsdCode +
                            " " +
                            toData?.businessMobileNo +
                            "\n" +
                            toData?.businessEmailId
                          }
                          cols={15}
                          rows={4}
                          maxLength={150}
                        ></textarea>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="d-flex flex-column w-100 mb-2">
                  <div className="border border-dark border-bottom-0 mt-2 w-100  bg-primary justify-content-center align-items-center">
                    <Row className="w-100 justify-content-center">
                      <div
                        className="d-flex w-25 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        <Col className="d-flex align-items-center justify-content-center fw-bold fs-5">
                          Item
                        </Col>
                      </div>
                      <div
                        className="d-flex w-25 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        <Col className="d-flex align-items-center justify-content-center fw-bold fs-5">
                          Rate
                        </Col>
                      </div>
                      <div
                        className="d-flex w-25 p-2"
                        style={{ borderRight: "1px solid black" }}
                      >
                        <Col className="d-flex align-items-center justify-content-center fw-bold fs-5">
                          Qty
                        </Col>
                      </div>
                      <div className="d-flex w-25 p-2">
                        <Col className="d-flex align-items-center justify-content-center fw-bold fs-5">
                          Total
                        </Col>
                      </div>
                    </Row>
                  </div>
                  <div className="border border-dark justify-content-center align-items-center">
                    {invData?.items.map((data, index) => (
                      <div className="w-100 justify-content-center align-items-center">
                        <Row
                          className="w-100 justify-content-start "
                          key={index}
                        >
                          <div
                            className="w-25 p-2"
                            style={{ borderRight: "1px solid black" }}
                          >
                            <Col className="d-flex align-items-center justify-content-start fw-bold fs-6 ms-2">
                              {data.itemName}
                            </Col>
                          </div>
                          <div
                            className="w-25 p-2"
                            style={{ borderRight: "1px solid black" }}
                          >
                            <Col className="d-flex align-items-center justify-content-start fw-bold fs-6">
                              {baseCurrency}&nbsp;
                              {data.rate.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Col>
                          </div>
                          <div
                            className="w-25 p-2"
                            style={{ borderRight: "1px solid black" }}
                          >
                            <Col className="d-flex align-items-center justify-content-start fw-bold fs-6">
                              {data.qty}
                            </Col>
                          </div>
                          <div className="w-25 p-2">
                            <Col className="d-flex align-items-center justify-content-start fw-bold fs-6">
                              {baseCurrency}&nbsp;
                              {data.total.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Col>
                          </div>
                        </Row>
                        <hr className="m-0"></hr>
                      </div>
                    ))}
                  </div>

                  <div>
                    <div className="w-100  justify-content-center align-items-center border border-dark border-top-0">
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            &nbsp;&nbsp;Subtotal
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            {baseCurrency}&nbsp;
                            {invData?.subTotal.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                      <hr className="m-0"></hr>
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            &nbsp;&nbsp;Discount
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            {baseCurrency}&nbsp;
                            {invData?.discount.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                      <hr className="m-0"></hr>
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex flex-1 align-items-center justify-content-start fw-bold">
                            &nbsp;&nbsp;Tax
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            {baseCurrency}&nbsp;
                            {invData?.tax.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                      <hr className="m-0"></hr>
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            &nbsp;&nbsp;Shipping
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            {baseCurrency}&nbsp;
                            {invData?.shipping.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                      <hr
                        className="m-0"
                        style={{ borderColor: "1px solid black" }}
                      ></hr>
                      <Row className="w-100 justify-content-center">
                        <div
                          className="w-75 p-2"
                          style={{ borderRight: "1px solid black" }}
                        >
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            &nbsp;&nbsp;Total
                          </Col>
                        </div>
                        <div className="w-25 p-2">
                          <Col className="d-flex align-items-center justify-content-start fw-bold">
                            {baseCurrency}&nbsp;
                            {invData?.total.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Col>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <label htmlFor="" className="fw-bold fs-4 mt-2">
                    Notes
                  </label>
                  <textarea
                    className="w-100 m-2 ms-0 me-0 border-0 rounded bg-light p-2"
                    style={{ resize: "none" }}
                    value={invData?.notes}
                    rows={2}
                    maxLength={300}
                    disabled
                  ></textarea>
                </div>

                <div className="mt-3 mb-5 w-100">
                  <div>
                    <h4>Payment Options</h4>
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <div>
                      {" "}
                      <p>{invData?.termsAndConditions}</p>
                    </div>
                    <div>
                      <img
                        className="image-input-wrapper w-125px h-125px"
                        style={{ objectFit: "contain" }}
                        src={invSetting?.documents[1]?.documentPath}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex w-100 gap-5 mt-4 justify-content-around">
                  {/* {invData?.invStatus === 1 && ( */}
                  <button
                    type="button"
                    className="btn rounded"
                    style={{
                      width: "250px",
                    }}
                    // onClick={() => {
                    //   handleDeleteCloseModal();
                    //   handleRecordPay();
                    // }}
                  >
                    Edit Quotation
                  </button>
                  {/* )} */}

                  <button
                    type="button"
                    className="btn rounded"
                    style={{
                      width: "250px",
                    }}
                    onClick={() => {
                      Swal.fire({
                        icon: "question",
                        text: "Are you sure you want to convert this Quotation to Invoice?",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      });
                    }}
                  >
                    Convert to Invoice
                  </button>
                </div>
              </div>
              <hr />

              <h4 className="ms-5">Quotation History</h4>

              <div className="timeline-container">
                {/* The line that connects the timeline circles */}
                <div className="timeline-line"></div>
                {timelineEvents.map((event, index) => (
                  <div className="timeline-event" key={index}>
                    <div className="timeline-left">
                      <div className="timeline-date">{event.date}</div>
                      <div className="timeline-time">{event.time}</div>
                    </div>
                    <div className="timeline-right">
                      <div className="timeline-circle"></div>
                      <div className="timeline-content">
                        <div className="timeline-description">
                          {event.description}
                        </div>
                        <div className="timeline-createdBy">
                          by {event.createdBy}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <RecordPayModal data={data?.data?.invoiceGuid} />
    </>
  );
}
