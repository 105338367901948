/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  defaultAlerts,
  defaultLogs,
  KTIcon,
  toAbsoluteUrl,
} from "../../../helpers";
import { enhancedFetch } from "../../../../app/modules/auth/core/_requests";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { fetchNotifications } from "../../../../firebase/NotificationSlice";


const HeaderNotificationsMenu: FC = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const API_URL = process.env.REACT_APP_WEB_API_URL;


  const dispatch = useDispatch();
  const { notifications, count } = useSelector(
    (state: RootState) => state.notificationLogs
  );

  useEffect(() => {
    dispatch(fetchNotifications({} as any) as any);
  }, [dispatch]);

  const [show, setShow] = useState(null);

  const btnRef = useRef<HTMLButtonElement | null>(null);
  const onClick = () => {
    btnRef.current?.setAttribute("data-kt-indicator", "on");
    setTimeout(() => {
      // Activate indicator
      btnRef.current?.removeAttribute("data-kt-indicator");
    }, 1000);
  };

  const ExpandNotification = (
    index: any,
    notificationGuid: string,
    status: number
  ) => {
    setShow((prevIndex) => (prevIndex === index ? -1 : index));
    if (status === 2) {
      updateNotification({
        notificationGuid,
        status: 3,
      });
      dispatch(fetchNotifications({} as any) as any);
    }
  };
  const updateNotification = async ({
    notificationGuid,
    status,
    interactionRef,
  }: any) => {
    try {
      const url = `${API_URL}/notification/updateNotificationLog`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        notificationGuid: notificationGuid,
        notificationStatus: status,
        ...(interactionRef !== null &&
          interactionRef !== "" && { interactionReference: interactionRef }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      dispatch(fetchNotifications({} as any) as any);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleButtonClick = (
    code: string,
    action: string,
    notificationGuid: string,
    buttonId: string
  ) => {
    if (action === "HOME_PAGE") {
      window.location.href = "/dashboard";
    } else {
      window.open(action, "_blank");
    }

    updateNotification({
      notificationGuid,
      status: 3,
      interactionRef: buttonId,
    });
  };

  return (
    <div
   
      className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
      data-kt-menu="true"
      data-kt-menu-dismiss='true'
    
    >
      <div
        className="d-flex flex-column bgi-no-repeat rounded-top"
        style={{
          backgroundImage: `url('${toAbsoluteUrl(
            "/media/misc/menu-header-bg.jpg"
          )}')`,
        }}
      >
        <h3 className="text-white fw-bold px-9 mt-10 mb-6">
          Communication Center{" "}
        </h3>

        <ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9"   onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
          <li className="nav-item">
            <a
              className="nav-link text-white opacity-75 opacity-state-100 pb-4 active"
              data-bs-toggle="tab"
              href="#kt_topbar_notifications_0"
            >
               <div className="position-relative">
                All
                <span
                  className="position-absolute translate-end badge rounded-pill  text-light"
                  style={{
                    backgroundColor: "#f82659",
                    bottom: "8px",
                    fontSize: "10px",
                  }}
                >
                  {
                    notifications.filter(
                      (item) => item?.notificationStatus === 2
                    ).length
                  }
                </span>
              </div>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link text-white opacity-75 opacity-state-100 pb-4 "
              data-bs-toggle="tab"
              href="#kt_topbar_notifications_1"
            >
              Alerts
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link text-white opacity-75 opacity-state-100 pb-4 "
              data-bs-toggle="tab"
              href="#kt_topbar_notifications_2"
            >
              <div className="position-relative">
                Notifications
                <span
                  className="position-absolute translate-end badge rounded-pill  text-light"
                  style={{
                    backgroundColor: "#f82659",
                    bottom: "8px",
                    fontSize: "10px",
                  }}
                >
                  {
                    notifications.filter(
                      (item) => item?.notificationStatus === 2
                    ).length
                  }
                </span>
              </div>
            </a>
          </li>

          {/* <li className="nav-item ms-5">
            <a
              className="nav-link text-white opacity-75 opacity-state-100 pb-4"
              data-bs-toggle="tab"
              href="#kt_topbar_notifications_3"
            >
              Logs
            </a>
          </li> */}
        </ul>
      </div>

      <div className="tab-content">
        <div
          className="tab-pane fade show active"
          id="kt_topbar_notifications_0"
          role="tabpanel"
        >
          <div className="scroll-y px-8" style={{ maxHeight: "440px" }}   onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
            {notifications?.map((alert, index) => (
              <div
                key={`alert${index}`}
                className="d-flex card shadow-sm flex-row py-4 px-4 mt-4 cursor-pointer"
                onClick={(e) => {
                  ExpandNotification(
                    index,
                    alert?.notificationDetails?.notificationGuid,
                    alert?.notificationStatus
                  );

                  // e.preventDefault();
                }}
              >
                <div className="mb-0  me-2  w-100">
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex flex-row align-items-center justify-content-between ">
                      {/* <div className="symbol symbol-35px me-4 ">
                        <span
                          className={clsx(
                            "symbol-label"
                            // `bg-light-${alert?.state as any}`
                          )}
                        >
                          {" "}
                          <i className="ki-outline ki-notification-on fs-2 fw-bold"></i>
                        </span>
                      </div> */}
                      

                      <div>
                        {alert?.notificationStatus === 2 && (
                          <span className="bullet bullet-dot bg-primary h-6px w-6px mb-1 " />
                        )}
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-between ">
                        <p
                          className={`fs-6 text-gray-800 text-nowrap text-hover-primary text-truncate ${alert?.notificationStatus === 2
                              ? "fw-bolder ms-2"
                              : "ms-4"
                            } m-0 p-0`}
                          style={{ maxWidth: "150px" }}
                        >
                          {alert?.notificationDetails?.title}
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <span className="badge badge-light ms-50 fs-8">
                        {new Date(alert?.createdDate)
                          .toISOString()
                          .split("T")[0] ===
                          new Date().toISOString().split("T")[0]
                          ? new Date(alert?.createdDate).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )
                          : alert?.createdDateString.split(",")[0]}
                      </span>
                    </div>
                  </div>

                  <div>
                    <div className="text-gray-400 fs-7 mt-2 ms-4">
                      {index === show ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: alert?.notificationDetails?.body || "",
                            }}
                          />
                          <img
                            src={alert?.notificationDetails?.mediaUrl}
                            alt=""
                            style={{ maxWidth: "100%", objectFit: "cover" }}
                          />
                          <div className="d-flex flex-row justify-content-start pt-2 gap-5">
                            {alert?.notificationDetails.buttons?.map(
                              (
                                {
                                  buttonId,
                                  name,
                                  code,
                                  action,
                                  type,
                                }: {
                                  buttonId: string;
                                  name: string;
                                  code: string;
                                  action: string;
                                  type: number;
                                },
                                index: number
                              ) => (
                                <button
                                  key={buttonId}
                                  className={`btn ${index === 0
                                      ? "btn-primary"
                                      : index === 1
                                        ? "btn-primary"
                                        : "btn-secondary"
                                    } pt-1 pb-1`}
                                  onClick={() =>
                                    handleButtonClick(
                                      code,
                                      action,
                                      alert?.notificationDetails
                                        ?.notificationGuid,
                                      buttonId
                                    )
                                  }
                                >
                                  {name}
                                </button>
                              )
                            )}
                          </div>
                        </>
                      ) : (
                        <span
                          className={`  ${alert?.notificationDetails?.body.length > 50
                              ? "d-flex flex-column"
                              : "d-flex flex-row"
                            }`}
                          dangerouslySetInnerHTML={{
                            __html:
                              (alert?.notificationDetails?.body || "").slice(
                                0,
                                150
                              ) + "...",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="py-3 text-center border-top" >
            <Link
              to="/communication-center"
              className=""
              style={{ color: "#0000ff" }}
             data-kt-menu-dismiss='true'
            >
              View All{" "}
              <KTIcon iconName="arrow-right text-light" className="fs-5" />
            </Link>

          </div>
        </div>
        <div
          className="tab-pane fade "
          id="kt_topbar_notifications_1"
          role="tabpanel"
        >
          <div className="scroll-y  px-8" style={{ maxHeight: "440px" }} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }} >
            {/* {defaultAlerts.map((alert, index) => (
              <div
                key={`alert${index}`}
                className="d-flex flex-row justify-content-between mt-4 ps-4 pe-4 py-4 card shadow-sm mb-2"
              >
                <div className="d-flex align-items-center ">
                  <div>
                    {
                      <span className="bullet bullet-dot bg-primary h-6px w-6px mb-4 me-2 " />
                    }
                  </div>

                  <div className="mb-0 ">
                    <a
                      href="#"
                      className="fs-6 text-gray-800 text-hover-primary fw-bolder"
                    >
                      {alert.title}
                    </a>
                    <div className="text-gray-400 fs-7">
                      {alert.description}
                    </div>
                  </div>
                </div>

                <span
                  className="badge badge-light fs-8"
                  style={{ maxHeight: "20px" }}
                >
                  {alert.time}
                </span>
              </div>
            ))} */}
          </div>

          <div className="py-3 text-center border-top">
            <Link
              to="/communication-center"
              className=""
              style={{ color: "#0000ff" }}
      data-kt-menu-dismiss='true'
            >
              View All{" "}
              <KTIcon iconName="arrow-right text-light" className="fs-5" />
            </Link>
          </div>
        </div>

        <div
          className="tab-pane fade "
          id="kt_topbar_notifications_2"
          role="tabpanel"
        >
          <div className="scroll-y  px-8 " style={{ maxHeight: "440px" }} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
            {notifications?.map((alert, index) => (
              <div
                key={`alert${index}`}
                className="d-flex card shadow-sm flex-row py-4 px-4 mt-4 cursor-pointer"
                onClick={(e) => {
                  ExpandNotification(
                    index,
                    alert?.notificationDetails?.notificationGuid,
                    alert?.notificationStatus
                  );

                  // e.preventDefault();
                }}
              >
                <div className="mb-0  me-2  w-100">
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex flex-row align-items-center justify-content-between ">
                      {/* <div className="symbol symbol-35px me-4 ">
                        <span
                          className={clsx(
                            "symbol-label"
                            // `bg-light-${alert?.state as any}`
                          )}
                        >
                          {" "}
                          <i className="ki-outline ki-notification-on fs-2 fw-bold"></i>
                        </span>
                      </div> */}

                      <div>
                        {alert?.notificationStatus === 2 && (
                          <span className="bullet bullet-dot bg-primary h-6px w-6px mb-1 " />
                        )}
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-between ">
                        <p
                          className={`fs-6 text-gray-800 text-nowrap text-hover-primary text-truncate ${alert?.notificationStatus === 2
                            ? "fw-bolder ms-2"
                            : "ms-4"
                            } m-0 p-0`}
                          style={{ maxWidth: "150px" }}
                        >
                          {alert?.notificationDetails?.title}
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <span className="badge badge-light ms-50 fs-8">
                        {new Date(alert?.createdDate)
                          .toISOString()
                          .split("T")[0] ===
                          new Date().toISOString().split("T")[0]
                          ? new Date(alert?.createdDate).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )
                          : alert?.createdDateString.split(",")[0]}
                      </span>
                    </div>
                  </div>

                  <div>
                    <div className="text-gray-400 fs-7 mt-2 ms-4">
                      {index === show ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: alert?.notificationDetails?.body || "",
                            }}
                          />
                          <img
                            src={alert?.notificationDetails?.mediaUrl}
                            alt=""
                            style={{ maxWidth: "100%", objectFit: "cover" }}
                          />
                          <div className="d-flex flex-row justify-content-start pt-2 gap-5">
                            {alert?.notificationDetails.buttons?.map(
                              (
                                {
                                  buttonId,
                                  name,
                                  code,
                                  action,
                                  type,
                                }: {
                                  buttonId: string;
                                  name: string;
                                  code: string;
                                  action: string;
                                  type: number;
                                },
                                index: number
                              ) => (
                                <button
                                  key={buttonId}
                                  className={`btn ${index === 0
                                    ? "btn-primary"
                                    : index === 1
                                      ? "btn-primary"
                                      : "btn-secondary"
                                    } pt-1 pb-1`}
                                  onClick={() =>
                                    handleButtonClick(
                                      code,
                                      action,
                                      alert?.notificationDetails
                                        ?.notificationGuid,
                                      buttonId
                                    )
                                  }
                                >
                                  {name}
                                </button>
                              )
                            )}
                          </div>
                        </>
                      ) : (
                        <span
                          className={`  ${alert?.notificationDetails?.body.length > 50
                            ? "d-flex flex-column"
                            : "d-flex flex-row"
                            }`}
                          dangerouslySetInnerHTML={{
                            __html:
                              (alert?.notificationDetails?.body || "").slice(
                                0,
                                150
                              ) + "...",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="py-5 text-center border-top " >
            <Link
              to="/communication-center"
              className=""
              style={{ color: "#0000ff" }}
             data-kt-menu-dismiss='true'
            >
              View All{" "}
              <KTIcon iconName="arrow-right text-light" className="fs-5" />
            </Link>
          </div>
        </div>

        <div
          className="tab-pane fade"
          id="kt_topbar_notifications_3"
          role="tabpanel"
        >
          <div className="scroll-y  px-8" style={{ maxHeight: "440px" }} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
            {defaultLogs.map((log, index) => (
              <div
                key={`alert${index}`}
                className="d-flex flex-row justify-content-between mt-4 ps-4 pe-4 py-4 card shadow-sm mb-2"
              >
                <div className="d-flex align-items-center ">
                  <div>
                    {
                      <span className="bullet bullet-dot bg-primary h-6px w-6px mb-5 me-2 " />
                    }
                  </div>

                  <div className="mb-0 ms-1">
                    <a
                      href="#"
                      className={clsx(
                        "w-70px badge",
                        `badge-light-${log.state}`,
                        "me-4 fw-bolder "
                      )}
                    >
                      {log?.code}
                    </a>
                    <div className="text-gray-400 fs-7 mt-2">
                      {log?.message}
                    </div>
                  </div>
                </div>

                <span
                  className="badge badge-light fs-8"
                  style={{ maxHeight: "20px" }}
                >
                  {log?.time}
                </span>
              </div>
            ))}
          </div>
          <div className="py-3 text-center border-top">
            <Link
              to="/communication-center"
              className="btn btn-color-gray-600 btn-active-color-primary text-light"
             data-kt-menu-dismiss='true'
            >
              View All{" "}
              <KTIcon iconName="arrow-right text-light" className="fs-5" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export { HeaderNotificationsMenu };
