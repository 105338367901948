import { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { Popover } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TableComponent } from "./QuoTable";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Authorize from "../../modules/auth/components/Authorize";

interface StatData {
  invoiceGeneratedAmount: number;
  invoicePaidAmount: number;
  allCount: number;
}

const Quotations = () => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const [isActive, setActive] = useState(0);
  const [numOfEmp, setNumOfInvoice] = useState(0);
  const [empStatus, setEmpStatus] = useState("Invoices Generated");
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<StatData | null>(null);

  const progress = Math.min(Math.max(numOfEmp, 0), 100);

  const navigate = useNavigate();

  let img = toAbsoluteUrl("/media/patterns/pattern-1.png");

  useEffect(() => {
    // Initialize popover when the component mounts
    const popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    popoverTriggerList.map((popoverTriggerEl) => new Popover(popoverTriggerEl));
  }, []);

  const handlePaySalary = (event: React.MouseEvent<HTMLElement>) => {
    const modalElement = document.getElementById("paySalary_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const tooltipInventory = <Tooltip id="tooltip-add-money">Inventory</Tooltip>;

  const tooltipCustomer = <Tooltip id="tooltip-arrow-right">Business</Tooltip>;

  const tooltipSettings = <Tooltip id="tooltip-count">Settings</Tooltip>;

  const getInvoiceStatistics = async () => {
    try {
      const url = `${API_URL}/invoice/getInvoiceStatistics`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };
      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setStatistics(data.message);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getInvoiceStatistics();
  }, []);

  return (
    <>
      <h4 className="mb-4">Quotation Dashboard</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card  d-flex flex-column flex-grow-1">
          <>
            <div className="gap-3 p-5">
              <div className="d-flex gap-8">
                <div
                  className="card shadow-sm d-flex flex-grow-1 p-3 bg-gray-300"
                  style={{ maxWidth: "450px", minWidth: "450px" }}
                >
                  <div
                    className="card-body p-10"
                    style={{ marginLeft: "20px" }}
                  >
                    <div className="d-flex flex-column gap-5 ">
                      <div>
                        <p
                          className="p-0 m-0 text-dark mb-5"
                          style={{ fontWeight: "bold" }}
                        >
                          Active Quotations
                          <span
                            className="text-dark-200"
                            style={{ fontWeight: "normal" }}
                          ></span>
                        </p>
                        <h4
                          className="p-0 m-0 text-dark mb-2"
                          style={{ fontSize: "35px" }}
                        >
                          {numOfEmp}
                        </h4>
                      </div>
                      <div className="d-flex gap-5">
                        <Authorize hasAnyPermission={["MENU_INVENTRY|VIEW"]}>
                          <OverlayTrigger
                            placement="top"
                            overlay={tooltipInventory}
                          >
                            <div
                              className="btn btn-icon bg-dark bg-hover-light text-hover-dark"
                              style={{ height: "30px", width: "30px" }}
                              onClick={() => navigate("/invoice/inventory")}
                            >
                              <span className="svg-icon svg-icon-1">
                                <i className="bi bi-cart3 text-light text-hover-dark"></i>
                              </span>
                            </div>
                          </OverlayTrigger>
                        </Authorize>
                        <Authorize hasAnyPermission={["MENU_BUSINESS|VIEW"]}>
                          <OverlayTrigger
                            placement="top"
                            overlay={tooltipCustomer}
                          >
                            <div
                              className="btn btn-icon bg-dark bg-hover-light text-hover-dark"
                              style={{ height: "30px", width: "30px" }}
                              onClick={() => {
                                navigate("/invoice/customers");
                              }}
                            >
                              <span className="svg-icon svg-icon-1">
                                <i className="bi bi-person-circle text-light text-hover-dark"></i>
                              </span>
                            </div>
                          </OverlayTrigger>
                        </Authorize>
                        <Authorize hasAnyPermission={["MENU_INVOICE|EDIT"]}>
                          <OverlayTrigger
                            placement="top"
                            overlay={tooltipSettings}
                          >
                            <div
                              className="btn btn-icon bg-dark bg-hover-light text-hover-dark"
                              style={{ height: "30px", width: "30px" }}
                              onClick={() => {
                                navigate("/invoice/settings");
                              }}
                            >
                              <span className="svg-icon svg-icon-1">
                                <i className="bi bi-gear text-light text-hover-dark"></i>
                              </span>
                            </div>
                          </OverlayTrigger>
                        </Authorize>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card shadow-sm d-flex  flex-grow-1 "
                  style={{ width: "100%" }}
                >
                  <div className="card-body p-5 flex-grow-1 p-0 m-0">
                    <div className="d-flex justify-content-between mb-3 flex-grow-1">
                      <h4 className="p-0 m-0">Quotation History</h4>
                    </div>
                    <br />

                    <div className="  w-100 d-flex flex-row gap-7">
                      <div className=" card shadow-sm bg-info w-100  p-10 ">
                        <div className="d-flex justify-content-start">
                          <h1 className="text-light fs-2x fw-bolder">
                            {" "}
                            {/* {statistics?.invoiceGeneratedAmount?.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            <span className="fs-9">({baseCurrency})</span> */}
                            500
                          </h1>
                          <br />
                          <br />
                        </div>
                        <div className="d-flex justify-content-start">
                          <p className="text-light fs-7 "> Quotations Raised</p>
                        </div>
                      </div>
                      <div className=" card shadow-sm bg-primary w-100  p-10 ">
                        <div className="d-flex justify-content-start">
                          <h1 className="text-light fs-2x fw-bolder">
                            {/* {statistics?.invoicePaidAmount?.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            <span className="fs-9">({baseCurrency})</span> */}
                            321
                          </h1>
                          <br />
                          <br />
                        </div>
                        <div className="d-flex justify-content-start">
                          <p className="text-light fs-7">Quotations Accepted</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="card shadow-sm d-flex flex-grow-1 ">
                <TableComponent
                  isActive={isActive}
                  setActive={setActive}
                  setNumOfInvoice={setNumOfInvoice}
                  setEmpStatus={setEmpStatus}
                />
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Quotations;
