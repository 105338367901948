/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, FC } from "react";
import { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikProps,
  FormikValues,
  useFormik,
} from "formik";
import clsx from "clsx";
import { useAuth } from "../../modules/auth/core/Auth";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { Role } from "../TeamBanking/Roles";
import { Accounts } from "../../interface/Interface";
import OTPInput from "react-otp-input";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileUploader } from "react-drag-drop-files";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";

interface TransactionModel {
  accountNumber: string;
  senderName: string;
  senderCustomerId: string;
  benefAccountNumber: string;
  benefName: string;
  benefCustomerId: string;
  txnId: string;
  txnType: string;
  date: string;
  time: string;
  totalAmount: string;
  amount: string;
  scAmount: string;
  type: string;
  status: string;
  currency: string;
  businessGuid: string;
  hvt: boolean;
}

// Interface for the Main Transaction Object
interface Transaction {
  date: string;
  payeeName: string;
  description: string;
  refrenceNo: string;
  amount: string;
  txnType: string;
  reconId: string;
  txnModels: TransactionModel[];
}

interface Txn {
  reconId: string;
  txnIds: string[];
}

const Reconcilation: FC = () => {
  const baseCurrency = sessionStorage.getItem("currency");
  const fileTypes = ["xlsx"];

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const details = sessionStorage.getItem("userDetails");
  const userDetails = details ? JSON.parse(details) : null;

  const OTPRef = useRef<HTMLDivElement | null>(null);
  const formikRef = useRef<FormikProps<FormikValues>>(null);

  const [errMsg, setErrMsg] = useState("");
  const [pin, setPin] = useState("");
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [req, setReq] = useState("");
  const [activeInput, setActiveInput] = useState(0);
  // const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [show, setShow] = useState(true);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [previousValue, setPreviousValue] = useState("");
  const [txns, setTxns] = useState<Transaction[]>([]);
  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Accounts>(
    accounts?.[0]
  );
  const [txnIds, setTxnIds] = useState<Txn[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [showButton, setShowButton] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    reference: "",
    amount: "",
  });
  const [error, setError] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<number[]>([]);
  const [currentData, setCurrentData] = useState<TransactionModel[]>([]);
  const [selectedTransactionIndex, setSelectedTransactionIndex] = useState<
    number | null
  >(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );

  const toggleDropdown = (index: number) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };
  // useEffect(() => {
  //   // Set focus on the active input whenever activeInput changes
  //   inputRefs.current[activeInput]?.focus();
  // }, [activeInput]);

  // const handleChange = (value: string, index: number): string => {
  //   // Only allow positive numbers
  //   if (/^\d*$/.test(value) && pin.length < 4) {
  //     const newOtp = [...otp];
  //     newOtp[index] = value;
  //     setOtp(newOtp);
  //     setPin(pin + value);

  //     if (value && index < otp.length - 1) {
  //       setActiveInput(index + 1);
  //     }
  //     return pin + value;
  //   }
  //   return pin;
  // };

  const reset = () => {
    setFormData({
      name: "",
      reference: "",
      amount: "",
    });
    setCurrentData([]);
    setSelectedAmount(0);
    setSelectedTransactionIndex(null);
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  // const OTPModalOpen = () => {
  //   const modalElement = document.getElementById("OTP_modal");
  //   const modal = new Modal(modalElement as Element);
  //   modal.show();
  // };

  const handleOTPCloseModal = () => {
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);
    setReq("");
    formikRef.current?.resetForm();
    const modalElement = OTPRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleEReceipt = () => {
    const modalElement = document.getElementById("e_receipt_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };
  const getReconData = async (accountNumber: string) => {
    try {
      const url = `${API_URL}/recon/viewReconData`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: accountNumber,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setTxns(data.results);
        const totalAmount = data?.results?.reduce((acc: any, item: any) => {
          return acc + parseFloat(item.amount);
        }, 0);

        setBalance(selectedAccount?.balance - totalAmount);
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setAccounts(data.results);
      setSelectedAccount(data?.results?.[0]);
      getReconData(data?.results?.[0]?.accountNumber);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const reconcile = async (reconId: string, txnIds: string[]) => {
    try {
      const url = `${API_URL}/recon/reconcile`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        reconId: reconId,
        txnIds: txnIds,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        toast(
          `Reconciliation done for Txn ID -
          ${data?.message?.txnIds?.[0]}`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        reset();
        getReconData(selectedAccount?.accountNumber);
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (OTPRef.current) {
  //     const modal = new Modal(OTPRef.current);

  //     OTPRef.current.addEventListener("shown.bs.modal", () => {
  //       inputRefs.current[activeInput]?.focus();
  //     });

  //     OTPRef.current.addEventListener("hidden.bs.modal", handleOTPCloseModal);
  //     return () => {
  //       OTPRef.current?.removeEventListener(
  //         "hidden.bs.modal",
  //         handleOTPCloseModal
  //       );
  //       modal.dispose();
  //     };
  //   }
  // }, []);

  const formik = useFormik({
    initialValues: {
      file: "",
      // date: "",
    },
    // validationSchema: Yup.object().shape({
    //   file: Yup.string().required("File is required"),
    //   // date: Yup.string().required("Amount is required"),
    // }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log("submit called");

      const data = {
        file: values?.file,
        // amount: values.date,
      };

      try {
        setSubmitting(true);
        uploadFile();
        const modal = Modal.getInstance("#kt_modal_upload");
        if (modal) {
          modal.hide();
        }
        // OTPModalOpen();
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
        // cancel(true);
      }
    },
    onReset: () => {
      //   setAuthority([]);
      //   setShowRadio(false);
    },
  });

  const uploadFile = async () => {
    const formData = new FormData();
    if (file) {
      formData.append("channel", "WEB");
      formData.append("bulkFile", file);
      formData.append("name", "reconFile");
      formData.append("accountNumber", "3201298810");
    }
    try {
      const url = `${API_URL}/recon/uploadReconFile`;

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      // Create the POST request options
      const requestOptions = {
        method: "POST",
        headers: headers,
        body: formData,
      };

      let response = await fetch(url, requestOptions);
      console.log(response);
      let data = await response.json();

      if (data.status.statusCode === 0) {
        setShowButton(true);
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const processFile = async () => {
    try {
      const url = `${API_URL}/recon/processFile`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        accountNumber: selectedAccount?.accountNumber,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        getReconData(selectedAccount?.accountNumber);
        setShowButton(false);
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (uploadedFile: File | null) => {
    console.log(uploadedFile);
    setFile(uploadedFile);
  };

  const handleFindMatchClick = (index: number) => {
    reset();
    if (selectedTransactionIndex === index) {
      setSelectedTransactionIndex(null); // Hide dropdown if the same button is clicked again
    } else {
      setSelectedTransactionIndex(index); // Show dropdown for the clicked button
    }
  };

  const handleCheckClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    amount: number,
    reconId: string,
    txnId: string
  ) => {
    const target = e.target as HTMLInputElement;

    setTxnIds((prevTxnIds) => {
      const existingTxn = prevTxnIds.find((t) => t.reconId === reconId);

      if (existingTxn) {
        if (target.checked) {
          // If the txnId doesn't already exist in txnIds, add it
          return prevTxnIds.map((t) =>
            t.reconId === reconId
              ? {
                  ...t,
                  txnIds: t.txnIds.includes(txnId)
                    ? t.txnIds
                    : [...t.txnIds, txnId],
                }
              : t
          );
        } else {
          // Remove the txnId if it's unchecked
          return prevTxnIds.map((t) =>
            t.reconId === reconId
              ? { ...t, txnIds: t.txnIds.filter((id) => id !== txnId) }
              : t
          );
        }
      } else {
        // If reconId doesn't exist in the list, add a new entry
        return target.checked
          ? [...prevTxnIds, { reconId: reconId, txnIds: [txnId] }]
          : prevTxnIds; // If unchecked and it's not in the list, do nothing
      }
    });

    // Update the selected amount based on whether the checkbox is checked or unchecked
    if (target.checked) {
      setSelectedAmount((prev) => prev + amount);
    } else {
      setSelectedAmount((prev) => prev - amount);
    }
  };

  const handleUpload = () => {
    const modalElement = document.getElementById("kt_modal_upload");

    if (modalElement) {
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    } else {
      console.error("Modal element not found");
    }
  };

  const handleFileRemove = (fileToRemove: any) => {
    setFile(null);
  };

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const value = event.target.value;

    if (value === "") {
      setPreviousValue("");
      return "";
    }

    if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
      setPreviousValue(value);
      return value;
    }
    return previousValue;
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentData = array?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(txns?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "amount" ? handleAmountChange(e) : value,
    }));
    setError("");
  };

  const handleSearch = async (reconId: string, txnType: string) => {
    if (formData.name || formData.reference || formData.amount) {
      try {
        const url = `${API_URL}/recon/findTxn`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Ensure you have access to the token
        };

        // Create an object with only the fields that have values
        const apiBody: any = {
          reconId: reconId, // This seems to be a constant value from your original code
          txnType: txnType, // This also seems to be a constant
        };

        if (formData.name) apiBody.payeeName = formData.name;
        if (formData.reference) apiBody.refrenceNo = formData.reference;
        if (formData.amount) apiBody.amount = parseFloat(formData.amount);

        // Add accountNumber if it's available in formData, otherwise use the constant value
        apiBody.accountNumber = "3201298810";

        const options = {
          method: "POST",
          headers,
          body: JSON.stringify(apiBody),
        };

        let response = await enhancedFetch(url, options);
        let data = await response.json();
        if (data.status.statusCode === 0) {
          setCurrentData(data?.results?.[0]?.txnModels);
          setError("");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An error occurred while searching. Please try again.");
      }
    } else {
      setError("At least one field is required");
    }
  };

  useEffect(() => {
    if (selectedAccount?.accountNumber)
      getReconData(selectedAccount?.accountNumber);
  }, [selectedAccount?.accountNumber]);

  return (
    <>
      <h3 className="mb-5">Reconcile Data</h3>

      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <div className="d-flex flex-row gap-3 align-items-center">
            <div className="d-flex flex-column">
              <p className="fs-3 fw-bold">
                ({selectedAccount?.currency}{" "}
                {balance.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                )
              </p>
              <p className="text-gray-600 fw-bold text-center">
                Accounting Balance
              </p>
            </div>
            <div className="d-flex flex-column">
              <p className="fs-3 fw-bold">
                ({selectedAccount?.currency}{" "}
                {selectedAccount?.balance.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                )
              </p>
              <p className="text-gray-600 fw-bold text-center">Xfin Balance</p>
            </div>
            {showButton && (
              <button
                className="btn fs-7 p-3 px-5"
                onClick={() => {
                  processFile();
                }}
              >
                Process File
              </button>
            )}
          </div>

          <div className="d-flex flex-1 align-items-center gap-5">
            <div>
              {" "}
              <select
                className="form-select text-gray cursor-pointer"
                value={selectedAccount ? selectedAccount.accountNumber : ""}
                onChange={(e) => {
                  const selectedAccountId = e.target.value;
                  const selectedAccount = accounts.find(
                    (account) => account.accountNumber === selectedAccountId
                  );
                  setSelectedAccount(
                    selectedAccount ? selectedAccount : accounts?.[0]
                  );
                }}
              >
                {accounts?.map((account) => (
                  <option
                    key={account.accountNumber}
                    value={account.accountNumber}
                  >
                    {account.accountNumber}
                    {"  "}({account.currency})
                  </option>
                ))}
              </select>
            </div>
            <button
              className="btn fs-7 p-3 px-5"
              onClick={() => {
                formik.resetForm();
                handleUpload();
              }}
            >
              <i className="ki-duotone ki-plus fs-2"></i>Upload File
            </button>
          </div>
        </div>

        <div className="card-body p-5">
          {/* <div className="d-flex "> */}
          {/* <label className="mb-3">Accounting Transactions</label> */}
          {txns && txns.length > 0 ? (
            txns?.slice(0, 10).map((tran, mainIndex) => (
              <div key={mainIndex}>
                <div className="d-flex flex-row w-100 gap-3 align-items-start">
                  <div
                    className="card shadow p-0 d-flex flex-column gap-3 mb-3"
                    style={{
                      width: "40%",
                      height: "75px",
                    }}
                  >
                    <div className="card-body p-5">
                      <div className="d-flex justify-content-between">
                        <h5 className="card-title">{tran.payeeName}</h5>
                        <h6
                          className="card-text"
                          style={{
                            color:
                              tran.txnType === "Cr" ? "#0000ff" : "#FF0000",
                          }}
                        >
                          {selectedAccount?.currency}{" "}
                          {Number(tran.amount).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </h6>
                      </div>
                      <div className="d-flex justify-content-between p-0">
                        <div className="d-flex p-0 gap-3">
                          <p
                            className="text-muted p-0 m-0"
                            style={{ fontSize: "12px" }}
                          >
                            {tran.date}
                          </p>
                        </div>
                        <div className="d-flex gap-3">
                          <div>
                            <i
                              className={`bi ${
                                tran.txnType === "Dr"
                                  ? "bi-arrow-up-square"
                                  : "bi-arrow-down-square"
                              }`}
                              style={{
                                color:
                                  tran.txnType === "Cr" ? "#0000ff" : "#FF0000",
                              }}
                            ></i>
                          </div>
                          <p
                            className="card-text"
                            style={{
                              fontSize: "12px",
                              color:
                                tran.txnType === "Cr" ? "#0000ff" : "#FF0000",
                            }}
                          >
                            {tran.txnType === "Dr" ? "Debit" : "Credit"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center mt-5"
                    style={{
                      width: "20%",
                    }}
                  >
                    {tran.txnModels.length !== 0 && (
                      <button
                        className="btn btn-primary rounded"
                        disabled={
                          !selectedIndex.includes(mainIndex) &&
                          txns[mainIndex].txnModels.length > 1
                        }
                        onClick={() => {
                          if (txns[mainIndex].txnModels.length > 1) {
                            // Find the matching Txn object based on reconId
                            const matchingTxn = txnIds.find(
                              (txn) => txn.reconId === tran.reconId
                            );

                            if (!matchingTxn) {
                              console.error(
                                `No matching transaction found for reconId: ${tran.reconId}`
                              );
                              return;
                            }
                            reconcile(tran.reconId, matchingTxn.txnIds);
                          } else {
                            reconcile(tran.reconId, [
                              tran?.txnModels[0]?.txnId,
                            ]);
                          }
                        }}
                      >
                        Ok
                      </button>
                    )}
                  </div>
                  <div
                    className="card shadow p-0 d-flex flex-column gap-3 mb-3"
                    style={{
                      width: "40%",
                    }}
                  >
                    <div
                      className="card-body p-5"
                      style={{
                        maxHeight: "200px",
                        minHeight: "75px",
                        overflow: "scroll",
                      }}
                    >
                      {tran?.txnModels?.length !== 0 ? (
                        <>
                          {tran?.txnModels?.map((txn, index) => (
                            <React.Fragment key={index}>
                              <div
                                className="d-flex align-items-start"
                                key={index}
                              >
                                {tran?.txnModels?.length > 1 ? (
                                  <div>
                                    <input
                                      type="radio"
                                      name={tran.reconId.toString()}
                                      className="form-check-input cursor-pointer me-3"
                                      onClick={() => {
                                        setSelectedIndex((prevIndices) => [
                                          ...prevIndices,
                                          mainIndex,
                                        ]);
                                        setTxnIds((prevTxnIds) => {
                                          const existingTxn = prevTxnIds.find(
                                            (t) => t.reconId === tran.reconId
                                          );
                                          if (existingTxn) {
                                            // If reconId already exists, replace its txnIds
                                            return prevTxnIds.map((t) =>
                                              t.reconId === tran.reconId
                                                ? { ...t, txnIds: [txn.txnId] }
                                                : t
                                            );
                                          } else {
                                            // If reconId doesn't exist, add a new entry
                                            return [
                                              ...prevTxnIds,
                                              {
                                                reconId: tran.reconId,
                                                txnIds: [txn.txnId],
                                              },
                                            ];
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                ) : null}

                                <div className="d-flex flex-column flex-grow-1">
                                  {" "}
                                  <div className="d-flex justify-content-between">
                                    <h5 className="card-title">
                                      {txn?.benefName}{" "}
                                      <i
                                        className="bi bi-info-circle text-primary cursor-pointer"
                                        data-kt-menu-trigger="click"
                                        // data-kt-menu-placement="auto"
                                      ></i>
                                      <div
                                        className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                                        data-kt-menu="true"
                                        id="details_dropdown"
                                      >
                                        <div className="p-5 d-flex flex-column gap-3">
                                          <div className="d-flex justify-content-between gap-5">
                                            <p
                                              className="fs-7 fw-semibold"
                                              style={{
                                                width: "40%",
                                              }}
                                            >
                                              Beneficiary account number
                                            </p>
                                            <p className="fs-7">
                                              {txn.benefAccountNumber}
                                            </p>
                                          </div>
                                          <div className="d-flex justify-content-between gap-5">
                                            <p className="fs-7 fw-semibold">
                                              Beneficiary name
                                            </p>
                                            <p
                                              className="fs-7 text-wrap"
                                              style={{
                                                wordBreak: "break-word",
                                              }}
                                            >
                                              {txn.benefName}
                                            </p>
                                          </div>
                                          <div className="d-flex justify-content-between gap-5">
                                            <p className="fs-7 fw-semibold">
                                              Transaction ID
                                            </p>
                                            <p className="fs-7">{txn.txnId}</p>
                                          </div>
                                          <div className="d-flex justify-content-between gap-5">
                                            <p className="fs-7 fw-semibold">
                                              Transaction Type
                                            </p>
                                            <p className="fs-7">
                                              {txn.txnType}
                                            </p>
                                          </div>
                                          <div className="d-flex justify-content-between gap-5">
                                            <p className="fs-7 fw-semibold">
                                              Date
                                            </p>
                                            <p className="fs-7">{txn.date}</p>
                                          </div>
                                          <div className="d-flex justify-content-between gap-5">
                                            <p className="fs-7 fw-semibold">
                                              Amount
                                            </p>
                                            <p className="fs-7">
                                              {txn.totalAmount}
                                            </p>
                                          </div>
                                          <div className="d-flex justify-content-between gap-5">
                                            <p className="fs-7 fw-semibold">
                                              Status
                                            </p>
                                            <p
                                              className={`fs-7 text-${
                                                txn.status === "Success"
                                                  ? "success"
                                                  : "danger"
                                              }`}
                                            >
                                              {txn.status}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </h5>

                                    <h6
                                      className="card-text"
                                      style={{
                                        color:
                                          txn?.type === "Cr"
                                            ? "#0000ff"
                                            : "#FF0000",
                                      }}
                                    >
                                      {selectedAccount?.currency}{" "}
                                      {Number(txn?.totalAmount)?.toLocaleString(
                                        "en-US",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </h6>
                                  </div>
                                  <div className="d-flex justify-content-between p-0">
                                    <div className="d-flex p-0 gap-3">
                                      <p
                                        className="text-muted p-0 m-0"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {txn.date}
                                      </p>
                                    </div>
                                    <div className="d-flex gap-3">
                                      <div>
                                        <i
                                          className={`bi ${
                                            txn.type === "Dr"
                                              ? "bi-arrow-up-square"
                                              : "bi-arrow-down-square"
                                          }`}
                                          style={{
                                            color:
                                              txn.type === "Cr"
                                                ? "#0000ff"
                                                : "#FF0000",
                                          }}
                                        ></i>
                                      </div>
                                      <p
                                        className="card-text"
                                        style={{
                                          fontSize: "12px",
                                          color:
                                            txn.type === "Cr"
                                              ? "#0000ff"
                                              : "#FF0000",
                                        }}
                                      >
                                        {txn?.type === "Dr"
                                          ? "Debit"
                                          : "Credit"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {tran?.txnModels?.length > 1 &&
                              index !== tran?.txnModels?.length - 1 ? (
                                <hr />
                              ) : null}
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <button
                          className="btn btn-primary rounded"
                          onClick={() => handleFindMatchClick(mainIndex)}
                        >
                          Find & Match
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {selectedTransactionIndex === mainIndex && (
                  <div className="card shadow dropdown-content mb-5">
                    <div className="card-header d-flex align-items-center">
                      <p className="fs-3">Find and match the transactions</p>
                    </div>
                    {/* Replace with your form and table */}
                    <div className="card-body p-5">
                      <>
                        <form className="d-flex flex-row flex-end gap-5 mb-3">
                          <div className="form-group">
                            <label className="fw-bold text-gray-600">
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Name"
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="form-group">
                            <label className="fw-bold text-gray-600">
                              Refrence
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter reference id"
                              name="reference"
                              value={formData.reference}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="form-group">
                            <label className="fw-bold text-gray-600">
                              Amount
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter amount"
                              name="amount"
                              value={formData.amount}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div
                            style={{
                              alignSelf: "flex-end",
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() =>
                                handleSearch(tran.reconId, tran.txnType)
                              }
                            >
                              Search
                            </button>
                          </div>
                        </form>
                        {error && (
                          <div className="text-danger mt-2 text-end">
                            {error}
                          </div>
                        )}
                      </>
                      <div
                        className="card p-2 pt-0"
                        style={{ maxHeight: "350px", overflowY: "auto" }}
                      >
                        <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                          <thead
                            className="bg-gray-200"
                            style={{
                              position: "sticky",
                              top: 0,
                              zIndex: 1,
                            }}
                          >
                            <tr className="text-start text-dark-600 fw-bold fs-6">
                              <th className="w-10px"></th>
                              <th className="min-w-100px p-5">Name</th>
                              <th className="min-w-100px p-5">Date</th>
                              <th className="min-w-100px p-5">Refrence</th>
                              <th className="min-w-100px p-5">Amount</th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentData && currentData.length > 0 ? (
                              currentData.map((item, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onMouseOver={(e) => {
                                      const target =
                                        e.currentTarget as HTMLElement;
                                      target.style.backgroundColor = "#f1f1f2";
                                      // target.style.transform =
                                      //   "translate3d(6px, -6px, 0)";
                                      // target.style.boxShadow =
                                      //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                                    }}
                                    onMouseOut={(e) => {
                                      const target =
                                        e.currentTarget as HTMLElement;
                                      target.style.backgroundColor = "white";
                                      // target.style.transform = "none";
                                      // target.style.boxShadow = "none";
                                    }}
                                  >
                                    <td className="p-5">
                                      <div className="form-check form-check-custom form-check-sm">
                                        <input
                                          type="checkbox"
                                          className="form-check-input cursor-pointer border border-dark"
                                          onClick={(e) =>
                                            handleCheckClick(
                                              e,
                                              +item.amount,
                                              tran.reconId,
                                              item.txnId
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td className="p-5 text-gray-600 position-relative">
                                      {item.benefName
                                        ? item.benefName
                                        : item.benefAccountNumber}{" "}
                                      <i
                                        className="bi bi-info-circle text-primary cursor-pointer"
                                        onClick={() => toggleDropdown(index)}
                                        onMouseOver={() =>
                                          toggleDropdown(index)
                                        }
                                        onMouseOut={() => toggleDropdown(-1)}
                                      ></i>
                                      {openDropdownIndex === index && (
                                        <div
                                          className="position-absolute start-100 top-0 bg-white border rounded shadow-sm p-3"
                                          style={{
                                            width: "300px",
                                            zIndex: 1000,
                                          }}
                                        >
                                          <div className="d-flex flex-column gap-3">
                                            <div className="d-flex justify-content-between gap-5">
                                              <p
                                                className="fs-7 fw-semibold"
                                                style={{ width: "40%" }}
                                              >
                                                Beneficiary account number
                                              </p>
                                              <p className="fs-7">
                                                {item.benefAccountNumber}
                                              </p>
                                            </div>
                                            <div className="d-flex justify-content-between gap-5">
                                              <p className="fs-7 fw-semibold">
                                                Beneficiary name
                                              </p>
                                              <p
                                                className="fs-7 text-wrap"
                                                style={{
                                                  wordBreak: "break-word",
                                                }}
                                              >
                                                {item.benefName
                                                  ? item.benefName
                                                  : item.benefAccountNumber}
                                              </p>
                                            </div>
                                            <div className="d-flex justify-content-between gap-5">
                                              <p className="fs-7 fw-semibold">
                                                Transaction ID
                                              </p>
                                              <p className="fs-7">
                                                {item.txnId}
                                              </p>
                                            </div>
                                            <div className="d-flex justify-content-between gap-5">
                                              <p className="fs-7 fw-semibold">
                                                Transaction Type
                                              </p>
                                              <p className="fs-7">
                                                {item.txnType}
                                              </p>
                                            </div>
                                            <div className="d-flex justify-content-between gap-5">
                                              <p className="fs-7 fw-semibold">
                                                Date
                                              </p>
                                              <p className="fs-7">
                                                {item.date}
                                              </p>
                                            </div>
                                            <div className="d-flex justify-content-between gap-5">
                                              <p className="fs-7 fw-semibold">
                                                Amount
                                              </p>
                                              <p className="fs-7">
                                                {item.totalAmount}
                                              </p>
                                            </div>
                                            <div className="d-flex justify-content-between gap-5">
                                              <p className="fs-7 fw-semibold">
                                                Status
                                              </p>
                                              <p
                                                className={`fs-7 text-${
                                                  item.status === "Success"
                                                    ? "success"
                                                    : "danger"
                                                }`}
                                              >
                                                {item.status}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </td>
                                    <td className="p-5 text-gray-600">
                                      {item.date}
                                    </td>
                                    <td className="p-5 text-gray-600">
                                      {item.txnId}
                                    </td>
                                    <td className="p-5 text-gray-600">
                                      {item.currency}{" "}
                                      {Number(item?.amount)?.toLocaleString(
                                        "en-US",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="card p-2">
                        <p className="fs-3 fw-bold text-decoration-underline">
                          The sum of your selected transactions must match the
                          money spent
                        </p>
                        <div className="d-flex flex-row justify-content-between mb-3">
                          <div className="">
                            <p className="fw-bold fs-5">
                              Amount spent : {baseCurrency}{" "}
                              {Number(tran?.amount)?.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                          <div className="d-flex">
                            <p className="fw-bold fs-5">
                              Amount selected : {baseCurrency}{" "}
                              {selectedAmount?.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </div>
                          <div className="">
                            {/* {Number(tran?.amount) !== selectedAmount ? (
                              <p className="text-danger">
                                Selected amount is less by: {baseCurrency}{" "}
                                {(
                                  Number(tran?.amount) - selectedAmount
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            ) : (
                              <div className="d-flex flex-row">
                                <p className="text-success">
                                  Selected amount matched
                                </p>
                              
                              </div>
                            )} */}
                          </div>
                        </div>
                        <div className="d-flex flex-end gap-5">
                          <button
                            type="button"
                            className="btn btn-primary"
                            disabled={selectedAmount < 1}
                            onClick={() => {
                              // Find the matching Txn object based on reconId
                              const matchingTxn = txnIds.find(
                                (txn) => txn.reconId === tran.reconId
                              );

                              if (!matchingTxn) {
                                console.error(
                                  `No matching transaction found for reconId: ${tran.reconId}`
                                );
                                return;
                              }
                              reconcile(tran.reconId, matchingTxn.txnIds);
                            }}
                          >
                            Reconcile
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={reset}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="card p-5">
              <p className="text-center">No data found to reconcile</p>
            </div>
          )}

          {/* </div> */}
        </div>

        {/* <div className="d-flex justify-content-between">
            <div className="p-5">
              <ul className="pagination">
                <li
                  className={`page-item double-arrow ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  key={0}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(1)}
                  >
                    <i className="previous"></i>
                    <i className="previous"></i>
                  </a>
                </li>
                <li
                  className={`page-item previous ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  key={1}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="previous"></i>
                  </a>
                </li>
                {renderPageNumbers()}
                <li
                  className={`page-item next ${
                    currentPage === Math.ceil(array?.length / itemsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                  key={2}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="next"></i>
                  </a>
                </li>
                <li
                  className={`page-item double-arrow 
                        ${
                          currentPage ===
                          Math.ceil(array?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }
                      `}
                  key={3}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() =>
                      handlePageChange(Math.ceil(array?.length / itemsPerPage))
                    }
                  >
                    <i className="next"></i>
                    <i className="next"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="p-5 d-flex align-items-center">
              <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                Total: {array?.length}&nbsp;&nbsp;
              </label>
              <select
                id="itemsPerPage"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div> */}
      </div>

      <div
        className="modal fade"
        id="kt_modal_upload"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div
            className="modal-content"
            style={{ maxHeight: "700px", overflow: "auto" }}
          >
            <div className="modal-header" id="kt_modal_upload_header">
              <h2 className="fw-bold mb-0">Upload File</h2>

              <div
                className="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-users-modal-action="close"
                data-bs-dismiss="modal"
                onClick={() => {
                  formik.resetForm();
                }}
              >
                <i className="ki-duotone ki-cross fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div>
            </div>

            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <form
                id="kt_modal_upload_form"
                className="form"
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div
                  className="d-flex flex-column scroll-y me-n7 pe-7"
                  id="kt_modal_upload_scroll"
                  data-kt-scroll-max-height="auto"
                >
                  {/* <label className="fs-5 fw-semibold form-label required">
                    Choose when you want to start and end this schedule
                  </label>
                  <div className="d-flex flex-row gap-5 w-100 mb-10">
                    <div className="w-100">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disablePast
                          format="DD/MM/YYYY"
                          className="w-100"
                          
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "From",
                              className: "form-control ",
                              style: {
                                maxWidth: "705px",
                              },
                            },
                            inputAdornment: {
                              style: { padding: "22.5px 0px" },
                            },
                          }}
                        />
                      </LocalizationProvider>
                      
                    </div>
                    <div className="w-100">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disablePast
                          className="w-100"
                          format="DD/MM/YYYY"
                         
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "To",
                              className: "form-control ",
                              style: {
                                maxWidth: "705px",
                              },
                            },
                            inputAdornment: {
                              style: { padding: "22.5px 0px" },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    
                    </div>
                  </div> */}
                  <label className="fs-5 fw-semibold form-label d-flex align-items-center">
                    Upload file{" "}
                    <p className="required fs-7 ms-3">
                      (only .csv and .xlsx files are allowed)
                    </p>
                  </label>
                  {file ? (
                    <div>
                      <div className="d-flex flex-column gap-5">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "2px dashed #888",
                            borderRadius: "8px",
                            padding: "20px",
                          }}
                        >
                          <div
                            className="card p-5 bg-secondary d-flex flex-column align-items-center justify-content-center "
                            style={{ width: "250px" }}
                          >
                            <div className="d-flex flex-row">
                              <p className="fw-bold">{file.size}&nbsp;</p>
                              <span>KB</span>
                            </div>
                            <div className="text-container my-3">
                              <p
                                className="text-truncate"
                                style={{ maxWidth: "15rem" }}
                              >
                                {file.name}
                              </p>
                            </div>
                            <p
                              className="text-decoration-underline text-danger cursor-pointer"
                              onClick={handleFileRemove}
                            >
                              Remove
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <FileUploader
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "2px dashed #888",
                          borderRadius: "8px",
                          padding: "20px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCloudUploadAlt}
                          size="4x"
                          style={{ color: "#888" }}
                        />
                        <p style={{ marginTop: "10px", color: "#888" }}>
                          Drag & drop files here, or click to select files
                        </p>
                      </div>
                    </FileUploader>
                  )}
                </div>

                <div className="text-center pt-15">
                  <button type="submit" className="btn btn-primary me-5">
                    <span className="indicator-label">Upload</span>
                    {formik.isSubmitting && (
                      <span className="indicator-progress">
                        Please wait...{" "}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                  <button
                    type="reset"
                    onClick={() => formik.resetForm()}
                    className="btn btn-light"
                    data-kt-users-modal-action="cancel"
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer limit={1} />
    </>
  );
};

export { Reconcilation };
