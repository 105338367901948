/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { PageTitle } from "../../../_metronic/layout/core";
import { Statistics } from "../components/Statistics";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from "bootstrap";
import { TransferModal } from "./AddMoney";

import qr from "../../../_metronic/assets/all-serviceIcons/scan-qr.svg";
import paymentlink from "../../../_metronic/assets/all-serviceIcons/cursor-click.svg";
import support from "../../../_metronic/assets/all-serviceIcons/messages-dots.svg";
import money from "../../../_metronic/assets/all-serviceIcons/home.svg";
import card from "../../../_metronic/assets/all-serviceIcons/card.svg";
import statistics from "../../../_metronic/assets/all-serviceIcons/chart-line-up.svg";
import bill from "../../../_metronic/assets/all-serviceIcons/money-bill-check.svg";
import loan from "../../../_metronic/assets/all-serviceIcons/hand-holding-dollar-circle.svg";
import statements from "../../../_metronic/assets/all-serviceIcons/papers-text.svg";
import invoiceIcon from "../../../_metronic/assets/all-serviceIcons/file-export-alt.svg";
import payrollIcon from "../../../_metronic/assets/all-serviceIcons/calculator-simple.svg";

import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { Api } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuMain";
import { CurrencyConversion } from "./CurrencyConversion";
import Authorize from "../../modules/auth/components/Authorize";
import { ApproverTxn } from "../TeamBanking/Approvals";

const chartData = [30, 40, 30];

interface Transaction {
  amount: number;
  txnId: string;
  currency: string;
  txnTypeName: string;
  dateTime: string;
  txnType: string;
  txnStatus: Number;
}

interface Accounts {
  accountNumber: string;
  accountType: string;
  balance: number;
  currency: string;
  functionCode: string;
  primaryFundId: string;
  primaryRefId: string;
  primaryRefType: number;
  tenantId: string;
  transactions: [];
  totalCredit: number;
  totalDebit: number;
}

export interface menu {
  menuCode: string;
  menuName: string;
  menuIcon: string;
  menuIndex: number;
  categoryIndex: number;
  isDashboardIcon: boolean;
}

export interface Menus {
  menuCategory: string;
  menus: menu[];
}

interface accDetails {
  currency: string;
  iconCode: string;
  goal: string;
  goalLimit: number;
  creditCondition: string;
  creditType: string;
  creditValue: number;
  creditFrequency: string;
  subAccountType: string;
  subAccountName: string;
  subAccountNumber: string;
  subAccountStatus: number;
  balance: number;
  createdDate: string;
}

interface QuickMenus {
  isDashboardIcon: boolean;
  menuCode: string;
  menuIcon: string;
  menuName: string;
}

interface Lifeime {
  totalCredit: number;
  totalDebit: number;
}

type MenuItem = {
  path: string;
  icon: string; // You can replace `string` with a more specific type if you have predefined icons
};

type UrlType = {
  [key: string]: MenuItem;
  MENU_CARDS: MenuItem;
  MENU_BUSINESS: MenuItem;
  MENU_VL: MenuItem;
  MENU_SR: MenuItem;
  MENU_STATISTICS: MenuItem;
  MENU_TEAM_MEM: MenuItem;
  MENU_PAYROLL: MenuItem;
  MENU_PAYE: MenuItem;
  MENU_TEAM_ROLE: MenuItem;
  MENU_LOAN: MenuItem;
  MENU_TEAM_APPVORALS: MenuItem;
  MENU_QR_CODE: MenuItem;
  MENU_TRF: MenuItem;
  MENU_IT: MenuItem;
  MENU_INVENTRY: MenuItem;
  MENU_ALL_TXN: MenuItem;
  MENU_LIVCHT: MenuItem;
  MENU_TEAM_INITIATION: MenuItem;
  MENU_INVOICE: MenuItem;
  MENU_PAY_LINK: MenuItem;
  MENU_VAT: MenuItem;
  MENU_STMNT: MenuItem;
  MENU_PAYEE: MenuItem;
  MENU_SECURITY: MenuItem;
  MENU_FAQ: MenuItem;
  MENU_SUB_ACCOUNTS: MenuItem;
  MENU_SUBSCRIPTION: MenuItem;
  MENU_BILLPAY: MenuItem;
  MENU_BULK_PAY: MenuItem;
  MENU_COMMN_CENTER: MenuItem;
  MENU_CURR_CONV: MenuItem;
};

const Url: UrlType = {
  MENU_CARDS: { path: "/my-card", icon: card },
  MENU_BUSINESS: { path: "/invoice/customers", icon: invoiceIcon },
  MENU_VL: { path: "/helpdesk/video-library", icon: support },
  MENU_SR: { path: "/helpdesk/service-ticket", icon: support },
  MENU_STATISTICS: { path: "/analytics", icon: statistics },
  MENU_TEAM_MEM: { path: "/team-banking/team-listing", icon: "team-icon" },
  MENU_PAYROLL: { path: "/payrollemp/payroll-dashboard", icon: payrollIcon },
  MENU_PAYE: { path: "/payrollemp/pay-salary", icon: payrollIcon },
  MENU_TEAM_ROLE: { path: "/team-banking/roles", icon: "roles-icon" },
  MENU_LOAN: { path: "/loan", icon: loan },
  MENU_TEAM_APPVORALS: {
    path: "/team-banking/approvals",
    icon: paymentlink,
  },
  MENU_QR_CODE: { path: "/qr/qr-dashboard", icon: qr },
  MENU_TRF: { path: "/money-movement/transfer-money", icon: money },
  MENU_IT: { path: "", icon: "it-icon" },
  MENU_INVENTRY: { path: "/invoice/inventory", icon: invoiceIcon },
  MENU_ALL_TXN: {
    path: "/money-movement/transaction-dashboard",
    icon: statements,
  },
  MENU_LIVCHT: { path: "", icon: support },
  MENU_TEAM_INITIATION: {
    path: "/team-banking/initiations",
    icon: "initiations-icon",
  },
  MENU_INVOICE: { path: "/invoice/invoice-dashboard", icon: invoiceIcon },
  MENU_PAY_LINK: {
    path: "/payment-link/payment-dashboard",
    icon: paymentlink,
  },
  MENU_VAT: { path: "", icon: "vat-icon" },
  MENU_STMNT: { path: "/statements", icon: statements },
  MENU_PAYEE: { path: "/money-movement/payee-management", icon: payrollIcon },
  MENU_SECURITY: { path: "/security", icon: "security-icon" },
  MENU_FAQ: { path: "/helpdesk/faq", icon: support },
  MENU_SUB_ACCOUNTS: { path: "/subaccount-listing", icon: "sub-accounts-icon" },
  MENU_SUBSCRIPTION: { path: "/subscription", icon: "subscription-icon" },
  MENU_BILLPAY: { path: "/paybill", icon: bill },
  MENU_BULK_PAY: {
    path: "/bulk-payment",
    icon: money,
  },
  MENU_COMMN_CENTER: {
    path: "/communication-center",
    icon: support,
  },
  MENU_CURR_CONV: { path: "", icon: "currency-conversion-icon" },
};

export const apiCall = {
  submit: () => {
    // Empty function by default
  },
};

const DashboardPage: FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const [currency, setCurrency] = useState("");
  const [shouldWrap, setShouldWrap] = useState(false);
  const [menu, setMenu] = useState<Menus[]>([]);
  const [quickLinks, setQuickLinks] = useState<QuickMenus[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [account, setAccount] = useState("");

  const [stmntDuration, setStmntDuration] = useState("1");
  const [AccSub, setAccSub] = useState<Accounts>(accounts[0]);
  const [clicked, setClicked] = useState(false);
  const [subAccounts, setSubAccounts] = useState<accDetails[]>([]);
  const [approvalTxns, setApprovalTxns] = useState<ApproverTxn[]>([]);

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const Currency = sessionStorage.getItem("currency");

  const totalIn = transactions
    ?.filter((item) => item.txnType === "C")
    .reduce((acc, item) => acc + item.amount, 0);

  const totalOut = transactions
    ?.filter((item) => item.txnType === "D")
    .reduce((acc, item) => acc + item.amount, 0);

  const navigate = useNavigate();

  useEffect(() => {
    const dashboardIconItems: QuickMenus[] = [];

    [
      menu?.[0],
      menu?.[1],
      menu?.[2],
      menu?.[3],
      menu?.[4],
      menu?.[5],
      menu?.[6],
      menu?.[7],
    ].forEach((menuItems) => {
      const filteredItems = menuItems?.menus?.filter(
        (item) => item["isDashboardIcon"] === true
      );
      if (filteredItems) {
        dashboardIconItems.push(...filteredItems);
      }
    });

    if (dashboardIconItems.length > 0) {
      setQuickLinks((prevQuickLinks) => [...dashboardIconItems]);
    }
  }, [menu]);

  const handleDateRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStmntDuration(e.target.value);
  };

  const handleSelect = (selectedIndex: number, e: any) => {
    setSelectedIndex(selectedIndex);
    setAccount(accounts[selectedIndex]?.accountNumber);
    setCurrency(accounts[selectedIndex]?.currency);
    setTransactions(accounts[selectedIndex]?.transactions);
  };

  useEffect(() => {
    setTransactions(accounts[selectedIndex]?.transactions);
    setAccount(accounts[selectedIndex]?.accountNumber);
    setCurrency(accounts[selectedIndex]?.currency);
    setAccSub(accounts[selectedIndex]);

    // setLifetime({accounts[selectedIndex].totalDebit})
  }, [handleSelect]);

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const tooltipAddMoney = <Tooltip id="tooltip-add-money">Add Money</Tooltip>;

  const tooltipArrowRight = (
    <Tooltip id="tooltip-arrow-right">Send Money</Tooltip>
  );

  const tooltipCurConv = (
    <Tooltip id="tooltip-arrow-right">Currency Conversion</Tooltip>
  );

  const tooltipCount = (
    <Tooltip id="tooltip-count">Sub Account: {subAccounts?.length}</Tooltip>
  );

  const tooltipThreeDots = <Tooltip id="tooltip-three-dots">More</Tooltip>;

  const handleTransfer = () => {
    const modalElement = document.getElementById("transfer_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setShouldWrap(screenWidth < 11 * 96); // Convert inches to pixels (assuming 1 inch = 96 pixels)
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Dashboard = async () => {
    try {
      const url = `${API_URL}/dashboard/getDashboard`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      sessionStorage.setItem(
        "currency",
        data?.message?.primaryCurrency.toString()
      );
      setAccounts(data?.message.accontList);
      setAccSub(data?.message.accontList[0]);
      setMenu(data?.message.menuCategoryList);
      sessionStorage?.setItem("profile",data?.message?.userProfileUrl)
      if (Api.setMenus) {
        Api?.setMenus(data?.message.menuCategoryList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getApproverTxns = async () => {
    try {
      const url = `${API_URL}/team/getApproverTxns`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovalTxns(data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Dashboard();
    getApproverTxns();
  }, []);

  apiCall.submit = Dashboard;

  const SubAccounts = async () => {
    try {
      const url = `${API_URL}/subAccount/getSubAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: AccSub?.accountNumber,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setSubAccounts(data?.message?.subAccounts);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (AccSub?.accountNumber) {
      SubAccounts();
    }
  }, [AccSub]);

  return (
    <>
      <style>
        {`
    #shadow-hover {
      // transition: box-shadow 0.3s;
      box-shadow: 4px 4px 10px #d1d1d1;
    }

    #shadow-hover:hover {
      box-shadow: 2px 2px 5px #71FF99;
    }
//     .carousel-control-prev-icon,

// .carousel-indicators {
//   display: none !important;
// }
.black-icon {
  color: black;
}
.btn:hover{
backgroundColor:#ffff
color:"red"
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
  background-size: 20px 20px;
}
  `}
      </style>

      <div
        className={`d-flex gap-5 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card  d-flex flex-column flex-grow-1">
          <div
            className={`d-flex justify-content-between gap-5 flex-nowrap
              } `}
            style={{
              paddingTop: "30px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <Carousel
              activeIndex={selectedIndex}
              onSelect={handleSelect}
              indicators={false}
              style={{ width: window.innerWidth < 11 * 96 ? "100%" : "800px" }}
              interval={null}
              className="card shadow-sm text-center bg-secondary"
            >
              {accounts.map((account, index) => (
                <Carousel.Item key={index}>
                  <div className=" d-flex flex-grow-1 ">
                    <div
                      className="d-flex flex-grow-1 p-10 ms-10 "
                      // style={{ marginLeft: "20px" }}
                    >
                      <div className="d-flex flex-column gap-5 ">
                        <div>
                          <p
                            className="p-0 m-0 text-dark mb-5 text-start"
                            style={{ fontWeight: "bold" }}
                          >
                            A/C:{" "}
                            <span
                              className="text-dark-200"
                              style={{ fontWeight: "normal" }}
                            >
                              {account.accountNumber}
                            </span>
                          </p>
                          <h4
                            className="p-0 m-0 text-dark mb-2"
                            style={{ fontSize: "35px" }}
                          >
                            <span
                              style={{ fontSize: "20px", fontWeight: "normal" }}
                            >
                              {account.currency}{" "}
                            </span>
                            {/* {account.balance.toString().split(".")[0]} */}

                            {account.balance
                              .toString()
                              .split(".")[0]
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            <span
                              style={{
                                fontSize: "15px",
                                position: "absolute",
                                fontWeight: "normal",
                              }}
                            >
                              .
                              {(
                                (account.balance.toString().split(".")[1] ||
                                  "00") + "00"
                              ).slice(0, 2)}
                            </span>
                          </h4>
                        </div>
                        {account?.accountNumber.startsWith("1010") ? null : (
                          <div className="d-flex gap-5">
                            <Authorize hasAnyPermission={["MENU_TRF|ADD"]}>
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltipAddMoney}
                              >
                                <button
                                  className=" bg-dark bg-hover-light text-hover-dark"
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    outline: "none",
                                    borderRadius: "5px",
                                    border: "0px",
                                  }}
                                  onClick={() => {
                                    handleTransfer();
                                    setClicked(true);
                                  }}
                                >
                                  <span className="svg-icon svg-icon-1">
                                    <i className="bi bi-plus-lg text-light text-hover-light"></i>
                                  </span>
                                </button>
                              </OverlayTrigger>
                            </Authorize>
                            <Authorize
                              hasAnyPermission={["MENU_SUB_ACCOUNTS|VIEW"]}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltipCount}
                              >
                                <button
                                  className="bg-dark bg-hover-light text-hover-dark"
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    outline: "none",
                                    borderRadius: "5px",
                                    border: "0px",
                                  }}
                                  onClick={() => {
                                    navigate("/subaccount-listing", {
                                      state: { data: account },
                                    });
                                  }}
                                >
                                  <span className="svg-icon svg-icon-1 ">
                                    <i className="bi bi-bank2 text-light text-hover-light"></i>
                                  </span>
                                </button>
                              </OverlayTrigger>
                            </Authorize>
                            <Authorize
                              hasAnyPermission={["MENU_CURR_CONV|ADD"]}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltipCurConv}
                              >
                                <button
                                  className=" bg-dark bg-hover-light text-hover-dark"
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    outline: "none",
                                    borderRadius: "5px",
                                    border: "0px",
                                  }}
                                  id="kt_modal_toggle_conversion"
                                >
                                  <span className="svg-icon svg-icon-1">
                                    <i className="bi bi-arrow-repeat text-light text-hover-light"></i>
                                  </span>
                                </button>
                              </OverlayTrigger>
                            </Authorize>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>

            <CurrencyConversion Data={AccSub} />

            <div
              className="card shadow-sm d-flex  flex-grow-1 "
              style={{ width: "100%" }}
            >
              <div className="card-body p-4 flex-grow-1 p-0 m-0">
                <div className="d-flex justify-content-between mb-3 flex-grow-1">
                  <h4 className="p-0 m-0">Account Life Time</h4>
                </div>

                <div className="card shadow-sm flex-grow-1 ">
                  <div
                    className="card-body flex-grow-1 p-3"
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  >
                    <div className="d-flex flex-row  justify-content-between">
                      <div className="d-flex flex-grow-1">
                        <div>
                          <h4 className="flex-grow-1 text-gray-800">
                            Total In
                          </h4>
                          {/* <p className='text-nowrap'>Monthly Income 10%</p> */}
                        </div>
                      </div>
                      <div>
                        <h4 className="text-gray-800">
                          {" "}
                          {currency}{" "}
                          {accounts[selectedIndex]?.totalCredit?.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </h4>
                      </div>
                    </div>

                    <div className="progress mt-2 mb-2">
                      <div
                        className="progress-bar bg-info"
                        role="progressbar"
                        style={{
                          width: "100%",
                        }}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="card shadow-sm mt-5">
                  <div
                    className="card-body p-3"
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                  >
                    <div className="d-flex flex-row gap-10 justify-content-between">
                      <div className="d-flex  gap-5">
                        {/* <div className=' d-flex align-items-center p-3' style={{ height: "35px", borderRadius: "10px", border: "1px solid grey", marginTop: "5px" }} ><i className="bi bi-currency-dollar"></i></div> */}
                        <div>
                          <h4 className="flex-grow-1 text-gray-800">
                            {" "}
                            Total Out
                          </h4>
                          {/* <p>Monthly Income 5%</p> */}
                        </div>
                      </div>
                      <div>
                        <h4 className="text-gray-800">
                          {currency}{" "}
                          {accounts[selectedIndex]?.totalDebit.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </h4>
                      </div>
                    </div>

                    <div className="progress mt-2 mb-2">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{
                          width: `${
                            (accounts[selectedIndex]?.totalDebit /
                              accounts[selectedIndex]?.totalCredit) *
                            100
                          }%`,
                        }}
                        aria-valuenow={accounts[selectedIndex]?.totalCredit}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="p-5">
            {/* <div className="card shadow-sm d-flex flex-grow-1 mt-5 ">
              <div className='p-5'>
                <div className='' >
                  <LineChart />
                </div>

              </div>

            </div> */}
            <div className="d-flex gap-2 flex-grow-1 flex-nowrap"></div>
            <div>
              <br />
              <div className="card shadow">
                <div className=" d-flex flex-row justify-content-between p-5">
                  <h5 className="m-0 p-0">Recent Transactions</h5>
                  {/* <Link to="/money-movement/dashboard" className="link-primary"> */}
                  <p
                    className=" m-0 cursor-pointer"
                    onClick={() => {
                      navigate("/money-movement/transaction-dashboard", {
                        state: { accountDetails: accounts[selectedIndex] },
                      });
                    }}
                    style={{ color: "#0000FF" }}
                  >
                    View more
                  </p>
                  {/* </Link> */}
                </div>
                <div className="p-5 d-flex flex-column gap-4">
                  {transactions?.slice(0, 10).map((transaction, index) => (
                    <div
                      className="card shadow p-0 d-flex flex-column gap-3 "
                      key={index}
                    >
                      <div className="card-body p-5 ">
                        <div className="d-flex justify-content-between">
                          <h5 className="card-title">
                            {transaction.txnTypeName}
                          </h5>
                          <h6
                            className={`card-text `}
                            style={{
                              color:
                                transaction.txnType === "C"
                                  ? "#0000ff"
                                  : "#FF0000",
                            }}
                          >
                            {transaction.currency}{" "}
                            {transaction.amount.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between p-0">
                          <div className="d-flex p-0 gap-3">
                            {/* <p className="text-muted p-0 m-0" style={{ fontSize: '12px' }}>
                              {new Date(transaction.dateTime).toLocaleDateString()}
                            </p>
                            <span className="text-muted">&nbsp;&#124;&nbsp;</span>
                            <p className="text-muted p-0 m-0" style={{ fontSize: '12px' }}>
                              {new Date(transaction.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                            </p> */}
                            <p
                              className="text-muted p-0 m-0"
                              style={{ fontSize: "12px" }}
                            >
                              {transaction.dateTime}
                            </p>
                            |
                            <p
                              className={`m-0 text-${
                                transaction?.txnStatus === 1
                                  ? "success"
                                  : "danger"
                              }`}
                              style={{ fontSize: "12px" }}
                            >
                              {transaction?.txnStatus === 1
                                ? "Success"
                                : "Failed"}
                            </p>
                          </div>
                          <div className="d-flex gap-3">
                            <div>
                              <i
                                className={`bi ${
                                  transaction.txnType === "D"
                                    ? "bi-arrow-up-square "
                                    : "bi-arrow-down-square "
                                }`}
                                style={{
                                  color:
                                    transaction.txnType === "C"
                                      ? "#0000ff"
                                      : "#FF0000",
                                }}
                              ></i>
                            </div>

                            <p
                              className={`card-text`}
                              // style={{  }}
                              style={{
                                fontSize: "12px",
                                color:
                                  transaction.txnType === "C"
                                    ? "#0000ff"
                                    : "#FF0000",
                              }}
                            >
                              {transaction.txnType === "D" ? "Debit" : "Credit"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <br />
              <Authorize hasAnyPermission={["MENU_STATISTICS|VIEW"]}>
                <div className="card shadow-sm mt-5 d-flex flex-grow-1  ">
                  <div className="card-body p-5 d-flex flex-column">
                    <div className="d-flex justify-content-between mb-5 p-0 m-0">
                      <div className="d-flex p-0 m-0">
                        <h4 className="p-0 m-0">Recent History</h4>
                      </div>
                      <div className="">
                        <select
                          className="shadow d-flex flex-row  form-select cursor-pointer "
                          data-control="select2"
                          style={{
                            outline: "none",
                            border: "none",
                            borderRadius: "5px",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                          }}
                          onChange={(e) => handleDateRangeChange(e)}
                        >
                          <option value="1" onSelect={() => {}}>
                            This Week
                          </option>
                          <option value="2" onSelect={() => {}}>
                            Last Week
                          </option>
                          <option value="3" onSelect={() => {}}>
                            Last 3 Months
                          </option>
                          <option value="4" onSelect={() => {}}>
                            Last 6 Months
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="card shadow-sm d-flex flex-grow-1  ">
                      <div className="p-10">
                        <div className="">
                          <Statistics
                            account={account}
                            currency={currency}
                            stmntDuration={stmntDuration}
                          />
                        </div>
                      </div>
                    </div>

                    {/* </div> */}
                  </div>
                </div>
              </Authorize>
            </div>
          </div>
        </div>
        <div
          className="card shadow-sm d-flex flex-grow-1"
          style={{ paddingTop: "15px", minWidth: "300px" }}
        >
          <div className="">
            <div className="card shadow p-5 mt-4 ms-4 me-4">
              <div className="d-flex flex-column ">
                <div>
                  {" "}
                  <h5 className="p-0 " style={{ color: "#0000FF" }}>
                    Quick Links
                  </h5>
                </div>
                {quickLinks?.map((icon, index) => (
                  <div
                    className="d-flex flex-row justify-content-between w-100 gap-5 p-2"
                    style={{ width: "16.666%" }}
                    key={index}
                  >
                    <div className="d-flex flex-row gap-5">
                      <Link to={Url[icon.menuCode].path || ""}>
                        <div
                          // className="btn btn-icon btn-light card"
                          className="d-flex"
                          // id="shadow-hover"
                          style={
                            {
                              // backgroundColor: QuickLinksData?.[index]?.color,
                            }
                          }
                        >
                          <img
                            src={
                              Url[icon.menuCode].icon
                                ? Url[icon.menuCode].icon
                                : ""
                            }
                            alt=""
                            style={{ height: "18px", width: "18px" }}
                          />
                        </div>
                      </Link>
                      <label
                        htmlFor=""
                        className="text-nowrap "
                        style={{ fontSize: "13px" }}
                      >
                        <Link
                          className="text-dark"
                          to={Url[icon.menuCode].path || ""}
                        >
                          {icon["menuName"] === "Transfer"
                            ? "Transfer Money"
                            : icon["menuName"]}
                        </Link>{" "}
                        {icon["menuName"] === "Approvals" ? (
                          approvalTxns?.filter((app) => app.status === 1)
                            .length > 0 ? (
                            <span
                              className="bullet bullet-dot bg-danger h-6px w-6px"
                              style={{
                                marginBottom: "1px",
                              }}
                            />
                          ) : null
                        ) : null}
                      </label>
                    </div>
                    <div>
                      <Link
                        className="text-dark"
                        to={Url[icon.menuCode].path || ""}
                      >
                        <div>
                          <i className="bi bi-arrow-right-short fs-5"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <h5 className="mt-8 mb-8 ms-5">Announcement</h5>
            <div className="p-0  d-flex flex-column  gap-10">
              <div className=" d-flex justify-content-center align-items-center flex-grow-1 gap-10">
                <img
                  src={Service}
                  alt=""
                  style={{ maxWidth: "300px", maxHeight: "220px" }}
                />
              </div>
              <div className=" d-flex justify-content-center align-items-center flex-grow-1">
                <img
                  src={Offer}
                  alt=""
                  style={{ maxWidth: "300px", maxHeight: "220px" }}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <TransferModal AccData={AccSub} clicked={clicked} />
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export default DashboardWrapper;
