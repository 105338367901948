/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, FC } from "react";
import { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";

import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikProps,
  FormikValues,
  useFormik,
} from "formik";
import clsx from "clsx";
import { useAuth } from "../../modules/auth/core/Auth";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { Role } from "../TeamBanking/Roles";
import { Accounts, TxnData } from "../../interface/Interface";
import OTPInput from "react-otp-input";
import { User } from "../TransferMoney/TransferModal";
import Authorize from "../../modules/auth/components/Authorize";
import { getTransactionDetail } from "../Transaction/TxnApi";
import { TxnReceipt } from "../Transaction/TxnReceipt";

interface RootObject {
  tenantId: string;
  referenceId: string;
  referenceType: string;
  currency: string;
  amount: number;
  accountNumber: string;
  clwId: number;
  clwGuid: string;
  validUpto: string;
  token: string;
  status: string;
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
  initiatedTxn: boolean;
  txnId: string;
}

interface RootObject {
  token: string;
  createdDate: string;
  validUpto: string;
  amount: number;
  accountNumber: string;
  status: string;
}

const CardlessPage: FC = () => {
  const baseCurrency = sessionStorage.getItem("currency");

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [button, setButton] = useState("Create");
  const [approvers, setApprovers] = useState<User[]>([]);

  const [approverName, setApproverName] = useState("");
  const [approverGuid, setApproverGuid] = useState("");
  const [apperrMsg, setAppErrMsg] = useState(false);
  const [authority, setAuthority] = useState(0);

  const tooltipFilter = <Tooltip id="tooltip-add-money">Filter</Tooltip>;

  const OTPRef = useRef<HTMLDivElement | null>(null);
  const formikRef = useRef<FormikProps<FormikValues>>(null);

  const [pinError, setPinError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [pin, setPin] = useState("");
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [req, setReq] = useState("");
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [show, setShow] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedData, setSelectedData] = useState<TxnData>({
    amount: 0,
    txnId: "",
    txnStatus: 0,
    accountNumber: "",
    currency: "",
    txnTypeName: "",
    dateTime: "",
    txnType: 0,
    category: "",
    note: "",
    scheduled: "",
    hours: "",
    receipt: [],
    businessGuid: "",
    senderName: "",
    senderCustomerId: "",
    benefName: "",
    benefCustomerId: "",
  });

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [previousValue, setPreviousValue] = useState("");
  const [roles, setRoles] = useState<Role[]>([]);
  const [accounts, setAccounts] = useState<Accounts[]>([]);
  const [withdrawals, setWithdrawals] = useState<RootObject[]>([]);
  const [withdrawalReceipt, setWithdrawalReceipt] = useState<RootObject | null>(
    null
  );
  const [filteredTransactions, setFilteredTransactions] = useState(withdrawals);
  const [selectedTab, setSelectedTab] = useState<string>("All");

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const ApproverList = async (amount: string) => {
    try {
      const url = `${API_URL}/team/getApproverList`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        currency: baseCurrency,
        amount: amount,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response?.json();
      if (data.status.statusCode === 0) {
        setApprovers(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    ApproverList(userDet?.userDetails?.zarLimit);
  }, []);

  useEffect(() => {
    if (userDet?.userDetails?.authority?.includes("2")) {
      setButton("Initiate");
    }
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const handleApprover = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setApproverGuid(e.target.value);
    const appName = approvers.find((app) => app.userGuid === e.target.value);
    if (appName) setApproverName(appName.name);
    formik.setFieldValue("approver", e.target.value);
  };

  const fieldLabels = {
    token: "Token ID",
    createdDate: "Created Date",
    validUpto: "Valid Till",
    amount: "Amount",
    accountNumber: "Account",
    status: "Status",
  };

  const orderedKeys = [
    "token",
    "createdDate",
    "validUpto",
    "amount",
    "accountNumber",
    "status",
  ];

  const OTPModalOpen = () => {
    const modalElement = document.getElementById("OTP_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleOTPCloseModal = () => {
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);
    setReq("");
    formikRef.current?.resetForm();
    const modalElement = OTPRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleEReceipt = (clwGuid: string) => {
    findReceipt(clwGuid)
      .then((res) => {
        const modalElement = document.getElementById("e_receipt_modal");
        modalElement
          ? res?.message
            ? new Modal(modalElement).show()
            : new Modal(modalElement).hide()
          : console.error("Modal element not found");
      })
      .catch((error) => {
        console.error("Error fetching receipt:", error);
      });
  };

  useEffect(() => {
    if (OTPRef.current) {
      const modal = new Modal(OTPRef.current);

      OTPRef.current.addEventListener("shown.bs.modal", () => {
        inputRefs.current[activeInput]?.focus();
      });

      OTPRef.current.addEventListener("hidden.bs.modal", handleOTPCloseModal);
      return () => {
        OTPRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleOTPCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const getStatusString = (statusCode: string | number): string => {
    switch (statusCode.toString()) {
      case "ACTIVE":
        return "Active";
      case "CANCELLED":
        return "Cancelled";
      case "SUCCESS":
        return "Success";
      case "FAILED":
        return "Failed";
      case "EXPIRED":
        return "Expired";
      default:
        return "";
    }
  };

  const getBootstrapColor = (statusCode: string | number): string => {
    switch (statusCode.toString()) {
      case "ACTIVE":
        return "primary";
      case "CANCELLED":
        return "warning";
      case "SUCCESS":
        return "success";
      case "FAILED":
        return "danger";
      case "EXPIRED":
        return "danger";
      default:
        return "";
    }
  };

  const formik = useFormik({
    initialValues: {
      accountNumber: "",
      amount: "",
      approver: "",
    },
    validationSchema: Yup.object().shape({
      accountNumber: Yup.string().required("Account is required"),
      
      amount: Yup.string()
        .required("Amount is required")
        .test("min-value", "Amount must be greater than 0", function (value) {
          const numericValue = Number(value);
          return !isNaN(numericValue) && numericValue > 0;
        })
        .test(
          "balance check",
          "Amount should be less than balance",
          function (value) {
            const account = this.parent.accountNumber;
            const acc = accounts.find((acc) => acc.accountNumber === account);
            if (acc) {
              return acc.balance > +value;
            }
            return false;
          }
        )
        
        .test(
          "multiple of 100",
          "Amount must be in multiples of 100",
          function (value) {
            const numericValue = Number(value);
            return !isNaN(numericValue) && numericValue % 100 === 0;
          }
        ),
      approver: Yup.string().test(
        "approver-required",
        "Select an approver",
        function (value) {
          // Access the buttontext value from the context or scope
          if (button === "Initiate") {
            return !!value; // Return true if value is present (valid), false otherwise (invalid)
          }
          return true; // If button is not 'Initiate', always return true (valid)
        }
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        account: values?.accountNumber,
        amount: values.amount,
      };

      try {
        setSubmitting(true);
        const modal = Modal.getInstance("#kt_modal_add_user");
        if (modal) {
          modal.hide();
        }
        OTPModalOpen();
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
        // cancel(true);
      }
    },
    onReset: () => {
      //   setAuthority([]);
      //   setShowRadio(false);
    },
  });

  const handleAdd = () => {
    const modalElement = document.getElementById("kt_modal_add_user");

    if (modalElement) {
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    } else {
      console.error("Modal element not found");
    }
  };

  const handleWithdraw = () => {
    const modalElement = document.getElementById("kt_modal_withdraw");

    if (modalElement) {
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    } else {
      console.error("Modal element not found");
    }
  };

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const value = event.target.value;

    if (value === "") {
      setPreviousValue("");
      return "";
    }

    if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
      setPreviousValue(value);
      return value;
    }
    return previousValue;
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "All") {
      setFilteredTransactions(withdrawals);
    } else if (tab === "Active") {
      const filteredData = withdrawals.filter(
        (item) => item.status === "ACTIVE"
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Success") {
      const filteredData = withdrawals.filter(
        (item) => item.status === "SUCCESS"
      );
      setFilteredTransactions(filteredData);
    } else if (tab === "Cancelled") {
      const filteredData = withdrawals.filter(
        (item) => item.status === "CANCELLED"
      );
      setFilteredTransactions(filteredData);
    }
  };
  useEffect(() => {
    // Filter data based on both selected tab and search query
    const filteredData = withdrawals?.filter((item) => {
      // Filter based on selected tab
      let isTabMatched = false;
      switch (selectedTab) {
        case "All":
          isTabMatched = true; // Always match for "All" tab
          break;
        case "Active":
          isTabMatched = item.status === "ACTIVE";
          break;
        case "Success":
          isTabMatched = item.status === "SUCCESS";
          break;
        case "Cancelled":
          isTabMatched = item.status === "CANCELLED";
          break;
        default:
          isTabMatched = true; // Default to true for unknown tabs
      }

      const isSearchMatched =
        item?.token.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.accountNumber.toLowerCase().includes(searchQuery.toLowerCase());

      // Return true if both conditions are matched
      return isTabMatched && isSearchMatched;
    });

    // Update filtered transactions
    setFilteredTransactions(filteredData);
  }, [withdrawals, searchQuery, selectedTab]);

  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response?.json();
      if (data.status.statusCode === 0) setAccounts(data.results);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const listWithdrawals = async () => {
    try {
      const url = `${API_URL}/cardlessWithdrawals/find`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        skipPagination: true,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response?.json();
      if (data.status.statusCode === 0) {
        setWithdrawals(data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const findReceipt = async (clwGuid: string) => {
    try {
      const url = `${API_URL}/cardlessWithdrawals/findByGuid`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({ clwGuid: clwGuid });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response?.json();
      if (data.status.statusCode === 0) {
        setWithdrawalReceipt(data?.message);
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const create = async (userdata: any) => {
    try {
      const url = `${API_URL}/cardlessWithdrawals/create`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      let body = JSON.stringify(userdata);

      if (button === "Initiate") {
        body = JSON.stringify({
          ...userdata,
          currency: baseCurrency,
          beneficiaryName: "Cardless Withdrawal",
          approverGuid: approverGuid,
          approverName: approverName,
          initiatedTxn: true,
        });
      }

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response?.json();
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const deleteToken = async (clwGuid: string) => {
    try {
      const url = `${API_URL}/cardlessWithdrawals/cancel`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({ clwGuid: clwGuid });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response?.json();
      if (data.status.statusCode === 0) {
        listWithdrawals();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Token cancelled",
        });
        // setMember(data.message);
      }
      if (data.status.statusCode === 1) {
        const msg = data.status.messageDescription;
        Swal.fire({
          icon: "error",
          title: "Clarification",
          text: msg,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadClick = async (txnId: string, accNum: string) => {
    try {
      if (txnId) {
        const transaction = await getTransactionDetail(
          API_URL,
          token,
          accNum,
          txnId
        );

        setSelectedData(transaction);
      }
    } catch (error) {
      console.error("Failed to enhancedFetch transaction details:", error);
    }
  };

  useEffect(() => {
    listWithdrawals();
    getAccounts();
  }, []);

  return (
    <>
      <h3 className="mb-5">Cardless Withdrawal</h3>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div id="kt_app_content_container" className="">
          <div className="card p-5">
            <div className="d-flex justify-content-between">
              <div className="">
                <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 d-flex flex-nowrap">
                  <li className="nav-item" key={1}>
                    <a
                      className="nav-link text-active-gray-800 active"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_1"
                      onClick={() => {
                        handleTabClick("All");
                      }}
                    >
                      All
                    </a>
                  </li>
                  <li className="nav-item" key={2}>
                    <a
                      className="nav-link text-active-gray-800"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_2"
                      onClick={() => {
                        handleTabClick("Active");
                      }}
                    >
                      Active
                    </a>
                  </li>
                  <li className="nav-item" key={3}>
                    <a
                      className="nav-link text-active-gray-800 text-nowrap"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_3"
                      onClick={() => {
                        handleTabClick("Success");
                      }}
                    >
                      Success
                    </a>
                  </li>
                  <li className="nav-item" key={4}>
                    <a
                      className="nav-link text-active-gray-800 text-nowrap"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_4"
                      onClick={() => {
                        handleTabClick("Cancelled");
                      }}
                    >
                      Cancelled
                    </a>
                  </li>
                </ul>
              </div>

              <div className="d-flex flex-nowrap align-items-center gap-5 me-5">
                <div className="d-flex align-items-center  position-relative my-1">
                  <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    value={searchQuery}
                    onChange={handleSearchChange}
                    type="text"
                    data-kt-docs-table-filter="search"
                    className="form-control ps-15"
                    placeholder="Search"
                  />
                </div>

                <Authorize hasAnyPermission={["MENU_CARDLESS_WITHDRAWAL|ADD"]}>
                  <button
                    className="btn fs-7 p-3 px-5 text-nowrap"
                    onClick={() => {
                      formik.resetForm();
                      handleAdd();
                    }}
                  >
                    <i className="ki-duotone ki-plus fs-2 text-nowrap"></i>
                    Generate Token
                  </button>
                </Authorize>
              </div>
            </div>
            <div className="tab-content " id="myTabContent">
              <div
                className=" tab-pane fade show active"
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <div className="card p-2">
                  <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                    <thead className="bg-gray-200">
                      <tr className="text-start text-dark-600 fw-bold fs-6">
                        <th className="min-w-125px p-5">Token ID</th>
                        <th className="min-w-125px p-5">Created Date</th>
                        <th className="min-w-125px p-5">Valid Till</th>
                        <th className="min-w-125px p-5">Amount</th>
                        <th className="min-w-125px p-5">Account</th>
                        <th className="min-w-125px p-5">Status</th>
                        <th className=" min-w-100px p-5">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData && currentData.length > 0 ? (
                        currentData?.map((item, index) => (
                          <tr
                            key={index}
                            onMouseOver={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "#f1f1f2";
                            }}
                            onMouseOut={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "white";
                            }}
                          >
                            <td
                              className={`p-5 ${
                                item?.status === "SUCCESS"
                                  ? "text-primary cursor-pointer"
                                  : "text-gray-600"
                              }`}
                              id={
                                item?.status === "SUCCESS"
                                  ? "kt_receipt_modal_toggle"
                                  : undefined
                              }
                              onClick={() => {
                                if (item.status === "SUCCESS") {
                                  handleDownloadClick(
                                    item?.txnId,
                                    item?.accountNumber
                                  );
                                }
                              }}
                            >
                              {item?.token}
                            </td>

                            <td className="p-5 text-gray-600">
                              {item?.createdDate}
                            </td>
                            <td className="p-5 text-gray-600">
                              {item?.validUpto}
                            </td>
                            <td className="p-5 text-gray-600">
                              {baseCurrency}&nbsp;{" "}
                              {item.amount.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                            </td>

                            <td className="p-5 text-gray-600">
                              {item?.accountNumber}
                            </td>
                            <td className="p-5 text-gray-600">
                              <span
                                className={`badge badge-light-${getBootstrapColor(
                                  item?.status
                                )} fw-bold`}
                              >
                                {getStatusString(item?.status)}
                              </span>
                            </td>

                            <td className="p-5 text-gray-600">
                              <Authorize
                                hasAnyPermission={[
                                  "MENU_CARDLESS_WITHDRAWAL|DELETE",
                                ]}
                              >
                                {item.status === "ACTIVE" && (
                                  <i
                                    className="bi bi-x-circle fs-3 cursor-pointer text-danger"
                                    data-bs-placement="top"
                                    title="Cancel"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      (async () => {
                                        const confirmationResult =
                                          await Swal.fire({
                                            title: "Confirmation!",
                                            text: "Are you sure want to cancel the token?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            cancelButtonText: "Cancel",
                                            confirmButtonText: "Confirm",
                                          });

                                        if (confirmationResult.isConfirmed) {
                                          deleteToken(item?.clwGuid);
                                        }
                                      })();
                                    }}
                                  ></i>
                                )}
                              </Authorize>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                <div className="card p-2">
                  <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                    <thead className="bg-gray-200">
                      <tr className="text-start text-dark-600 fw-bold fs-6">
                        <th className="min-w-125px p-5">Token ID</th>
                        <th className="min-w-125px p-5">Created Date</th>
                        <th className="min-w-125px p-5">Valid Till</th>
                        <th className="min-w-125px p-5">Amount</th>
                        <th className="min-w-125px p-5">Account</th>
                        <th className="min-w-125px p-5">Status</th>
                        <th className=" min-w-100px p-5">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData && currentData.length > 0 ? (
                        currentData?.map((item, index) => (
                          <tr
                            key={index}
                            onMouseOver={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "#f1f1f2";
                            }}
                            onMouseOut={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "white";
                            }}
                          >
                            <td
                              // className={`p-5 text-${
                              //   user.status === "3"
                              //     ? "primary cursor-pointer"
                              //     : "text-gray-600"
                              // }`}
                              className="p-5 text-gray-600"
                              // onClick={() => {
                              //   if (user.status === "3") {
                              //     handleEReceipt();
                              //   }
                              // }}
                            >
                              {item?.token}
                            </td>
                            <td className="p-5 text-gray-600">
                              {item?.createdDate}
                            </td>
                            <td className="p-5 text-gray-600">
                              {item?.validUpto}
                            </td>
                            <td className="p-5 text-gray-600">
                              {baseCurrency}&nbsp;{" "}
                              {item.amount.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                            </td>

                            <td className="p-5 text-gray-600">
                              {item?.accountNumber}
                            </td>
                            <td className="p-5 text-gray-600">
                              <span
                                className={`badge badge-light-${getBootstrapColor(
                                  item?.status
                                )} fw-bold`}
                              >
                                {getStatusString(item?.status)}
                              </span>
                            </td>
                            <td className="p-5 text-gray-600">
                              <Authorize
                                hasAnyPermission={[
                                  "MENU_CARDLESS_WITHDRAWAL|DELETE",
                                ]}
                              >
                                {item.status === "ACTIVE" && (
                                  <i
                                    className="ki-outline ki-trash fs-4 text-danger"
                                    data-bs-placement="top"
                                    style={{ cursor: "pointer" }}
                                    title="Delete"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      (async () => {
                                        const confirmationResult =
                                          await Swal.fire({
                                            title: "Confirmation!",
                                            text: "Are you sure want to cancell the token?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            cancelButtonText: "Cancel",
                                            confirmButtonText: "Confirm",
                                          });

                                        if (confirmationResult.isConfirmed) {
                                          deleteToken(item?.clwGuid);
                                        }
                                      })();
                                    }}
                                  ></i>
                                )}
                              </Authorize>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                <div className="card p-2">
                  <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                    <thead className="bg-gray-200">
                      <tr className="text-start text-dark-600 fw-bold fs-6">
                        <th className="min-w-125px p-5">Token ID</th>
                        <th className="min-w-125px p-5">Created Date</th>
                        <th className="min-w-125px p-5">Valid Till</th>
                        <th className="min-w-125px p-5">Amount</th>
                        <th className="min-w-125px p-5">Account</th>
                        <th className="min-w-125px p-5">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData && currentData.length > 0 ? (
                        currentData?.map((item, index) => (
                          <tr
                            key={index}
                            onMouseOver={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "#f1f1f2";
                            }}
                            onMouseOut={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "white";
                            }}
                          >
                            <td
                              className={`p-5 text-${
                                item?.status === "SUCCESS"
                                  ? "primary cursor-pointer"
                                  : "text-gray-600"
                              }`}
                              onClick={() => {
                                if (item.status === "SUCCESS") {
                                  handleDownloadClick(
                                    item?.txnId,
                                    item?.accountNumber
                                  );
                                }
                              }}
                            >
                              {item?.token}
                            </td>
                            <td className="p-5 text-gray-600">
                              {item?.createdDate}
                            </td>
                            <td className="p-5 text-gray-600">
                              {item?.validUpto}
                            </td>
                            <td className="p-5 text-gray-600">
                              {baseCurrency}&nbsp;{" "}
                              {item.amount.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                            </td>

                            <td className="p-5 text-gray-600">
                              {item?.accountNumber}
                            </td>
                            <td className="p-5 text-gray-600">
                              <span
                                className={`badge badge-light-${getBootstrapColor(
                                  item?.status
                                )} fw-bold`}
                              >
                                {getStatusString(item?.status)}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
                <div className="card p-2">
                  <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                    <thead className="bg-gray-200">
                      <tr className="text-start text-dark-600 fw-bold fs-6">
                        <th className="min-w-125px p-5">Token ID</th>
                        <th className="min-w-125px p-5">Created Date</th>
                        <th className="min-w-125px p-5">Valid Till</th>
                        <th className="min-w-125px p-5">Amount</th>
                        <th className="min-w-125px p-5">Account</th>
                        <th className="min-w-125px p-5">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData && currentData.length > 0 ? (
                        currentData?.map((item, index) => (
                          <tr
                            key={index}
                            onMouseOver={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "#f1f1f2";
                            }}
                            onMouseOut={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              target.style.backgroundColor = "white";
                            }}
                          >
                            <td
                              // className={`p-5 text-${
                              //   user.status === "3"
                              //     ? "primary cursor-pointer"
                              //     : "text-gray-600"
                              // }`}
                              className="p-5 text-gray-600"
                              // onClick={() => {
                              //   if (user.status === "3") {
                              //     handleEReceipt();
                              //   }
                              // }}
                            >
                              {item?.token}
                            </td>
                            <td className="p-5 text-gray-600">
                              {item?.createdDate}
                            </td>
                            <td className="p-5 text-gray-600">
                              {item?.validUpto}
                            </td>
                            <td className="p-5 text-gray-600">
                              {baseCurrency}&nbsp;{" "}
                              {item.amount.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                            </td>

                            <td className="p-5 text-gray-600">
                              {item?.accountNumber}
                            </td>
                            <td className="p-5 text-gray-600">
                              <span
                                className={`badge badge-light-${getBootstrapColor(
                                  item?.status
                                )} fw-bold`}
                              >
                                {getStatusString(item?.status)}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div className="p-5">
                  <ul className="pagination">
                    <li
                      className={`page-item double-arrow ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={0}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(1)}
                      >
                        <i className="previous"></i>
                        <i className="previous"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      key={1}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="previous"></i>
                      </a>
                    </li>
                    {renderPageNumbers()}
                    <li
                      className={`page-item next ${
                        currentPage ===
                        Math.ceil(filteredTransactions?.length / itemsPerPage)
                          ? "disabled"
                          : ""
                      }`}
                      key={2}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="next"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item double-arrow 
                        ${
                          currentPage ===
                          Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }
                      `}
                      key={3}
                    >
                      <a
                        href="#"
                        className="page-link"
                        onClick={() =>
                          handlePageChange(
                            Math.ceil(
                              filteredTransactions?.length / itemsPerPage
                            )
                          )
                        }
                      >
                        <i className="next"></i>
                        <i className="next"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="p-5 d-flex align-items-center">
                  <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
                    Total: {filteredTransactions?.length}&nbsp;&nbsp;
                  </label>
                  <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={75}>75</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="kt_modal_add_user"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div
                className="modal-content"
                style={{ maxHeight: "700px", overflow: "auto" }}
              >
                <div className="modal-header" id="kt_modal_add_user_header">
                  <h2 className="fw-bold">Create Token</h2>

                  <div
                    className="btn btn-icon btn-sm btn-active-icon-primary"
                    data-kt-users-modal-action="close"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      formik.resetForm();
                    }}
                  >
                    <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                  </div>
                </div>

                <div
                  className="modal-body scroll-y mx-5 mx-xl-15 my-7"
                  style={{
                    display: authority === 3 ? "flex" : "",
                    minHeight: "300px",
                    alignItems: authority === 3 ? "center" : "center",
                    justifyContent: authority === 3 ? "center" : "center",
                  }}
                >
                  {authority === 3 ? (
                    <div>
                      <p className="fw-bold text-center text-danger">
                        You don't have access to this feature.
                      </p>

                      <p className="fw-bold text-center text-danger">
                        Please contact admin.
                      </p>
                    </div>
                  ) : (
                    <form
                      id="kt_modal_add_user_form"
                      className="form"
                      onSubmit={formik.handleSubmit}
                      noValidate
                    >
                      <div className="d-flex flex-column scroll-y me-n7 pe-7">
                        <div className="mb-7">
                          <label className="required fw-bold fs-6 mb-2">
                            Select account
                          </label>

                          <select
                            {...formik.getFieldProps("accountNumber")}
                            name="accountNumber"
                            className="form-select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.accountNumber}
                          >
                            <option selected>Select an account</option>
                            {accounts
                              ?.filter((acc) => acc.currency !== "USD")
                              .map((acc) => (
                                <option
                                  value={acc?.accountNumber}
                                  key={acc?.accountNumber}
                                >
                                  {acc.accountNumber}&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                  {acc.currency}&nbsp;
                                  {acc.balance.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </option>
                              ))}
                          </select>
                          {formik.touched.accountNumber &&
                            formik.errors.accountNumber && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">
                                    {formik.errors.accountNumber}
                                  </span>
                                </div>
                              </div>
                            )}
                        </div>
                        <div
                          className="fv-row"
                          style={{ position: "relative" }}
                        >
                          <label className="required fw-bold fs-6">
                            Enter amount
                          </label>

                          <input
                            placeholder="Enter amount"
                            {...formik.getFieldProps("amount")}
                            type="text"
                            name="amount"
                            className={clsx(
                              "form-control  mb-lg-0 ps-20",
                              {
                                "is-invalid":
                                  formik.touched.amount && formik.errors.amount,
                              },
                              {
                                "is-valid":
                                  formik.touched.amount &&
                                  !formik.errors.amount,
                              }
                            )}
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                e.target.value === "0"
                              ) {
                                formik.setFieldValue("amount", "");
                                return;
                              }
                              formik.setFieldValue(
                                "amount",
                                handleAmountChange(e)
                              );
                            }}
                            onBlur={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              formik.handleBlur(e);
                              if (e.target.value === "") {
                                ApproverList("0");
                              }

                              if (
                                /^\d{1,12}(\.\d{0,2})?$/.test(e.target.value)
                              ) {
                                ApproverList(e.target.value);
                              }
                            }}
                            value={formik.values.amount}
                            autoComplete="off"
                            style={{ paddingLeft: "30px" }} // Adjust padding for left alignment
                          />

                          <span
                            className="fs-6 fw-bold text-gray-700"
                            style={{
                              position: "absolute",
                              top: "50%", // Center vertically
                              left: "10px", // Adjust as needed
                              // transform: "translateY(-50%)",
                              pointerEvents: "none",
                              transition: "0.2s", // Optional: Add transition for smoothness
                            }}
                          >
                            {baseCurrency}
                          </span>
                        </div>

                        {formik.touched.amount && formik.errors.amount && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">{formik.errors.amount}</span>
                            </div>
                          </div>
                        )}

                        {button === "Initiate" ? (
                          <div className="mb-7 mt-7">
                            <label className="required fw-bold fs-6 mb-2">
                              Select Approver
                            </label>
                            <select
                              {...formik.getFieldProps("approver")}
                              id="approver"
                              name="approver"
                              className="form-select"
                              onChange={handleApprover}
                            >
                              <option value="">Select Approver</option>
                              {approvers.map((approv, key) => (
                                <option value={approv.userGuid}>
                                  {approv.name}
                                </option>
                              ))}
                            </select>
                            {formik.touched.approver && formik.errors.approver && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">
                                    {formik.errors.approver}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>

                      <div className="text-center pt-15">
                        <button
                          type="reset"
                          onClick={() => formik.resetForm()}
                          className="btn btn-light me-3"
                          data-kt-users-modal-action="cancel"
                        >
                          Discard
                        </button>

                        <button type="submit" className="btn ">
                          <span className="indicator-label">
                            {button} Token
                          </span>
                          {formik.isSubmitting && (
                            <span className="indicator-progress">
                              Please wait...{" "}
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" tabIndex={-1} id="OTP_modal" ref={OTPRef}>
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row justify-content-end">
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center pb-5">
                <div className="label fs-3 fw-bold">Enter Your PIN</div>

                <div className="label p-0">
                  <p className="lorem-ipsum-dolor mb-3 text-nowrap fs-5">
                    Enter your PIN to confirm
                  </p>
                </div>
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    pin: "",
                  }}
                  validationSchema={Yup.object({
                    pin: Yup.string()
                      .matches(/^[ A-Za-z0-9_@./#&+-]*$/, "Invalid characters")
                      .min(4, "Pin must be at least 4 characters")
                      .required("Pin is required"),
                  })}
                  onSubmit={async (values, { resetForm }) => {
                    handleOTPCloseModal();
                    const data = {
                      accountNumber: formik?.values?.accountNumber,
                      amount: formik?.values?.amount,
                      transactionPIN: values?.pin,
                    };

                    const res = await create(data);
                    if (res.status.statusCode === 0) {
                      await listWithdrawals();
                      formik.resetForm();
                      if (button === "Initiate") {
                        Swal.fire({
                          icon: "success",
                          title: "Confirmation",
                          html: "Initiation successful <br> Pending for approval",
                        });
                      } else {
                        console.log(res);

                        Swal.fire({
                          icon: "success",
                          title: "Confirmation",
                          html: `
                          <b>Token:</b> ${res?.message?.token} <br>
                          <div style="margin-top: 5px;">
                            <b>Valid Upto:</b> ${res?.message?.validUpto}
                          </div>
                        `,
                          text: "Token Created",
                        });
                      }
                    } else if (res.status.statusCode === 1) {
                      const msg = res.status.messageDescription;
                      Swal.fire({
                        icon: "error",
                        title: "Clarification",
                        text: `${msg}`,
                      }).then((result) => {
                        if (result.isConfirmed) OTPModalOpen();
                      });
                    }
                  }}
                >
                  {({ handleSubmit }) => (
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                      }}
                    >
                      <div>
                        <Field name="pin">
                          {({
                            field,
                            meta,
                            form,
                          }: {
                            field: any;
                            meta: any;
                            form: FormikProps<any>;
                          }) => (
                            <div>
                              <OTPInput
                                {...field}
                                value={otp.join("")}
                                onChange={(value: string) => {
                                  value
                                    .split("")
                                    .forEach((char, index) =>
                                      handleChange(char, index)
                                    );
                                }}
                                numInputs={4}
                                renderSeparator={<span>&nbsp;&nbsp;</span>}
                                renderInput={(props, index) => (
                                  <input
                                    {...props}
                                    value={otp[index]}
                                    ref={(el) =>
                                      (inputRefs.current[index] = el)
                                    }
                                    onChange={(e) => {
                                      const pin = handleChange(
                                        e.target.value,
                                        index
                                      );
                                      form.setFieldValue("pin", pin);
                                      setErrMsg("");
                                    }}
                                    onKeyDown={(e) => {
                                      const pin = handleKeyDown(e, index);
                                      form.setFieldValue("pin", pin);
                                      setErrMsg("");
                                    }}
                                    type={show ? "password" : "text"}
                                    autoFocus={true}
                                    disabled={index !== activeInput}
                                    onFocus={() => setActiveInput(index)}
                                    className="text-center rounded mt-3 border border-1 border-dark"
                                    style={{ height: "50px", width: "50px" }}
                                  />
                                )}
                              />
                            </div>
                          )}
                        </Field>
                        <p
                          className="text-center cursor-pointer mb-0 mt-2 text-decoration-underline"
                          onClick={() => setShow(!show)}
                        >
                          {show ? "Show PIN" : "Hide PIN"}
                        </p>
                        <ErrorMessage
                          name="pin"
                          component="div"
                          className="text-danger text-center"
                        />
                      </div>

                      <br />
                      {errMsg && (
                        <p className="text-danger text-center">{errMsg}</p>
                      )}

                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          type="submit"
                          className="btn rounded"
                          style={{
                            width: "226px",
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        tabIndex={-1}
        id="e_receipt_modal"
        // ref={modalRef}
        // data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered w-400px">
          <div className="modal-content">
            <div className="modal-head d-flex flex-row justify-content-between align-items-center p-4 pb-0 mx-3">
              <p className="fs-3 pb-0 mt-4">Token Withdrawl</p>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>

            <div className="modal-body p-0 py-10">
              <div className="mx-5">
                <div className="d-flex flex-column justify-content-center mx-10">
                  <div
                    className="card shadow-sm p-5 w-300px"
                    style={{ maxHeight: "500px", overflow: "auto" }}
                  >
                    {withdrawalReceipt &&
                      orderedKeys
                        .filter((key) => key in withdrawalReceipt)
                        .map((key) => (
                          <div
                            key={key}
                            className="d-flex justify-content-between mb-2"
                          >
                            <div>
                              <p>
                                {fieldLabels[key as keyof typeof fieldLabels]}
                              </p>
                            </div>
                            <div>
                              <p
                                className={`fw-bold badge badge-${
                                  key === "status" ? "primary" : ""
                                }`}
                              >
                                {String(
                                  withdrawalReceipt[
                                    key as keyof typeof fieldLabels
                                  ]
                                )}
                              </p>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-10">
                  <button
                    type="button"
                    className="btn rounded"
                    style={{ width: "300px" }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    //   onClick={() => downloadPDF(data as ReceiptData)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TxnReceipt data={selectedData} />
    </>
  );
};

export { CardlessPage };
